import { useContext, useState, useEffect, Fragment } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

function Condition() {
  const BASE_URL = useContext(UrlContext);
  const PAGE_URL = BASE_URL + `conditions`;
  const BLOCK_SIZE = 10;

  const [classification, setClassification] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [cancelDate, setCancelDate] = useState(new Date());
  const [cancelDateBetween, setCancelDateBetween] = useState(new Date());
  const [cancelDateCondition, setCancelDateCondition] = useState('');
  const [drugName, setDrugName] = useState([]);
  const [drugSunbEtc, setDrugSunbEtc] = useState([]);
  const [effect, setEffect] = useState([]);
  const [atcCode, setAtcCode] = useState([]);

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);
  const [drugFormKind, setDrugFormKind] = useState([]);
  const [drugSunbCondition, setDrugSunbCondition] = useState([]);
  const [isBetween, setIsBetween] = useState(false);
  const [isMultipleSunb, setIsMultipleSunb] = useState(false);
  const [isMoreSunb, setIsMoreSunb] = useState(false);

  useEffect(() => {
    init();
    getDrugFormKind();
    getPostList();
  }, []);

  useEffect(() => {
    getPostList();
  }, [no]);

  useEffect(() => {
    if (cancelDate !== null) {
      let yyyy = cancelDate.getFullYear();
      let mm = String(cancelDate.getMonth() + 1).padStart(2, '0');
      let dd = String(cancelDate.getDate()).padStart(2, '0');
      setPost({ ...post, ['cancelDate']: yyyy + '-' + mm + '-' + dd });
    }
  }, [cancelDate]);

  useEffect(() => {
    if (cancelDateBetween !== null) {
      let yyyy = cancelDateBetween.getFullYear();
      let mm = String(cancelDateBetween.getMonth() + 1).padStart(2, '0');
      let dd = String(cancelDateBetween.getDate()).padStart(2, '0');
      setPost({ ...post, ['cancelDateBetween']: yyyy + '-' + mm + '-' + dd });
    }
  }, [cancelDateBetween]);

  useEffect(() => {
    if (cancelDateCondition === '사이') {
      setIsBetween(true);
    } else if (cancelDateCondition === '') {
      setIsBetween(false);
      setCancelDateBetween(null);
      setPost({ ...post, ['cancelDate']: null, ['cancelDateBetween']: null });
    } else {
      setIsBetween(false);
      setCancelDateBetween(null);
      setPost({ ...post, ['cancelDateBetween']: null });
    }
  }, [cancelDateCondition]);

  const init = () => {
    setCancelDate(null);
    setCancelDateBetween(null);
    setDrugName(['']);
    setDrugSunbEtc(['']);
    setEffect(['']);
    setAtcCode(['']);
    return {
      idx: '', solution: '', drugForm: '', cancelDate: null, cancelDateBetween: null, cancelDateCondition: '', nullValue: ''
      , drugClass: '일반', drugName: [''], drugSunbEtc: [''], atcCode: [''], kpicAtc: '', clsCode: '', effect: ['']
      , durAge: '', durPreg: '', durSenior: '', itemIngrType: '', unit: '', volume: '', volumeBetween: '', sunbCondition: '이상'
    }
  };

  const getDrugFormKind = () => {
    fetch(`${PAGE_URL}/drug/form`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setDrugFormKind(data.data);
        }
      });
  };

  const isMultipleFlag = (e) => {
    const { name, value } = e.target;
    setIsMultipleSunb(parseInt(value, 10));

    resetSunbValue();
    setDrugSunbEtc(['']);
  };

  const addSunbValue = () => {
    if (post.drugSunbEtc.length > 0) {
      setIsMoreSunb(true);

      fetch(`${BASE_URL}sunb?name=${post.drugSunbEtc}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            console.log(data.data);
            setDrugSunbCondition(data.data);
          }
        });
    } else { alert('성분을 입력하세요.'); }
  };

  const resetSunbValue = () => {
    setIsMoreSunb(false);
    setDrugSunbEtc(['']);
    setPost({ ...post, ['drugSunbEtc']: [''], ['unit']: '', ['volume']: '', ['volumeBetween']: '', ['sunbCondition']: '이상' });
  };

  const getPostList = () => {
    fetch(`${PAGE_URL}/all?classification=${classification}&keyword=${keyword}&page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const writeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') {
      if (no !== 1) {
        setNo(1);
      } else {
        getPostList();
      }
    }
  };

  const showLayer = (e) => {
    e.preventDefault();
    setPost(init());
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const addMultipleName = () => {
    setDrugName([...drugName, '']);
  };

  const addMultipleSunb = () => {
    setDrugSunbEtc([...drugSunbEtc, '']);
  };

  const addMultipleEffect = () => {
    setEffect([...effect, '']);
  };

  const addInput = () => {
    setAtcCode([...atcCode, '']);
  };

  const edit = (data) => {
    console.log(data);
    if (data.atcCode !== null) { setAtcCode(data.atcCode); }
    data.drugSunbEtc.length === 1 ? setIsMultipleSunb(0) : setIsMultipleSunb(1);
    setDrugSunbEtc(data.drugSunbEtc);
    setPost(data);
    setIsLayer(true);
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'cancelDateCondition') {
      setCancelDateCondition(value);
    }
    setPost({ ...post, [name]: value });
  };

  const addDrugName = (e, idx) => {
    const { value } = e.target;
    const inputs = drugName;
    inputs[idx] = value;
    setDrugName(inputs);
    setPost({ ...post, ['drugName']: drugName });
  };

  const addDrugSunbEtc = (e, idx) => {
    const { value } = e.target;
    const inputs = drugSunbEtc;
    inputs[idx] = value;
    setDrugSunbEtc(inputs);
    setPost({ ...post, ['drugSunbEtc']: drugSunbEtc });
  };

  const addEffect = (e, idx) => {
    const { value } = e.target;
    const inputs = effect;
    inputs[idx] = value;
    setEffect(inputs);
    setPost({ ...post, ['effect']: effect });
  };

  const addAtcCode = (e, idx) => {
    const { value } = e.target;
    const inputs = atcCode;
    inputs[idx] = value;
    setAtcCode(inputs);
    setPost({ ...post, ['atcCode']: atcCode });
  };

  const valid = () => {
    if (post.solution.length === 0) {
      alert('솔루션 번호를 입력해 주세요.'); return false;
    }
    if (isMoreSunb === true) {
      if (post.sunbCondition === '범위' && (post.volume.length === 0 || post.volumeBetween.length === 0)) {
        alert('함량의 범위를 지정해 주세요.'); return false;
      } else if (post.volume.length === 0 || post.unit.length === 0) {
        alert('함량과 단위를 입력해 주세요.'); return false;
      }
    }
    if (post.cancelDate !== null && post.cancelDateCondition.length === 0) {
      alert('취하일자의 검색조건을 입력해 주세요.'); return false;
    }
    if (post.cancelDate === null && post.nullValue === '1') {
      alert('취하일자를 선택해 주세요.'); return false;
    }
    return true;
  };

  const save = () => {
    console.log(post);

    if (valid() === true) {
      fetch(`${PAGE_URL}/save`, {
        method: 'POST',
        body: JSON.stringify(post),
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            const dataKey = data.data.idx;
            const postKey = postList.findIndex(list => list.idx === dataKey);

            if (postKey !== -1) {
              setPostList(
                postList.map(list =>
                  list.idx !== dataKey ? list : data.data
                )
              );
            } else {
              setPostList(post => [...post, data.data]);
            }

            setIsLayer(false);
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  const remove = (key) => {
    if (key !== undefined && key > 0 && window.confirm('솔루션 조건을 삭제하시겠습니까?') === true) {
      fetch(`${PAGE_URL}/delete/${key}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            setPostList(postList.filter(post => post.idx !== key));
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="yakgorithm" title="솔루션 조건관리"/>
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <select onChange={(e) => setClassification(e.target.value)} className="schmenu inp">
                          <option value="">전체</option>
                          <option value="solution">솔루션</option>
                        </select>
                      </th>
                      <td colSpan="2">
                        <input onChange={(writeKeyword)} onKeyPress={(e) => search(e)} value={keyword || ''} type="text" className="inp valm" style={{ width: '277px' }} />
                      </td>
                      <td>
                        <p className="btnlist">
                          <button onClick={(e) => search(e)} type="button" className="btnsch">검색</button>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d_table mt30">
                <table className="dtable" summary="솔루션 조건관리 목록 입니다.">
                  <caption>솔루션 조건관리 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="80" />
                    <col width="200" />
                    <col width="130" />
                    <col width="130" />
                    <col width="*" />
                    <col width="140" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">솔루션</th>
                      <th scope="col">종류</th>
                      <th scope="col">제품명</th>
                      <th scope="col">성분/함량</th>
                      <th scope="col">취하일자</th>
                      <th scope="col">효능/효과</th>
                      <th scope="col">변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.map(post => (
                      <tr key={post.idx} className="ov">
                        <td>{post.solution}</td>
                        <td>{post.drugClass}</td>
                        <td>
                          {post.drugName.map((p, i) => (
                            i === 0 ? (<span key={i}>{p}</span>) : <span key={i}>, {p}</span>
                          ))}
                        </td>
                        <td>
                          {post.drugSunbEtc.map((p, i) => (
                            i === 0 ? (<span key={i}>{p}</span>) : <span key={i}>, {p}</span>
                          ))}
                        </td>
                        <td>{post.cancelDate}</td>
                        <td>
                          {post.effect.map((p, i) => (
                            i === 0 ? (<span key={i}>{p}</span>) : <span key={i}>, {p}</span>
                          ))}
                        </td>
                        <td>
                          <button onClick={() => edit(post)} className="tbbtn btprimary">수정</button>
                          <button onClick={() => remove(post.idx)} className="tbbtn btblack">삭제</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={showLayer} className="btprimary">등록</button></p>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>솔루션 조건 등록</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form>
                      <table className="dvtable" summary="솔루션 조건 등록 입니다.">
                      <caption>솔루션 조건 등록</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>인덱스</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input readOnly value={post.idx || ''} type="text" name="idx" style={{ width: '300px' }} className="inp read" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>솔루션</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.solution || ''} type="text" name="solution" className="inp" />
                            </p>
                          </td>
                          <th>제형</th>
                          <td>
                            <p className="pl6">
                              <select onChange={writeInput} value={post.drugForm || ''} name="drugForm" className="valm ml10 mr35" style={{width:'126px'}}>
                                <option value="">선택하세요.</option>
                                {drugFormKind.map((data, index) => (
                                  <option key={data} value={data}>{data}</option>
                                ))}
                              </select>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>취하일자</th>
                          <td colSpan="3">
                            <div className="pl6">
                              <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={cancelDate} value={post.cancelDate || ''} onChange={e => setCancelDate(e)} customInput={
                                <input type="text" className="inp mr10" />
                              } />
                              {
                                isBetween
                                  &&
                                  <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={cancelDateBetween} value={post.cancelDateBetween || ''} onChange={e => setCancelDateBetween(e)} customInput={
                                    <input type="text" className="inp" />
                                  } />
                              }
                            </div>
                            <div className="mt5 pl6">
                              <label>
                                <input onChange={writeInput} type="radio" name="cancelDateCondition" value="이전" checked={post.cancelDateCondition === "이전"} />
                                <span className="ml5">이전</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="cancelDateCondition" value="이후" checked={post.cancelDateCondition === "이후"} />
                                <span className="ml5">이후</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="cancelDateCondition" value="사이" checked={post.cancelDateCondition === "사이"} />
                                <span className="ml5">사이</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="cancelDateCondition" value="일치" checked={post.cancelDateCondition === "일치"} />
                                <span className="ml5">일치</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="cancelDateCondition" value="" checked={post.cancelDateCondition === ""} />
                                <span className="ml5">해당없음</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="checkbox" name="nullValue" value={post.nullValue === '1' ? '0' : '1'} checked={post.nullValue === '1'} />
                                <span className="ml5">null 포함</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>제품명</th>
                          <td colSpan="3">
                            <div className="pl6">
                              {drugName.map((input, index) => (
                                <p key={index} style={{ display: 'inline-block' }}>
                                  <input onChange={e => addDrugName(e, index)} value={post.drugName[index] || ''} type="text" name="drugName" className="inp valm" />
                                  <button onClick={addMultipleName} type="button" className="schmorebtn on" title="제품명 추가"></button>
                                </p>
                              ))}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan="2">성분</th>
                          <td colSpan="3">
                            <div className="pl6">
                              <label>
                                <input onChange={isMultipleFlag} type="radio" name="multiple" value="0" checked={isMultipleSunb == 0} />
                                <span className="ml5">성분 1개 입력</span>
                              </label>
                              <label>
                                <input onChange={isMultipleFlag} type="radio" name="multiple" value="1" checked={isMultipleSunb == 1} />
                                <span className="ml5">성분 여러개 입력</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        {
                          isMultipleSunb == 0 ? (
                            <tr>
                              <td colSpan="3">
                                <div className="pl6">
                                  <p style={{ display: 'inline-block' }}>
                                    <input onChange={e => addDrugSunbEtc(e, 0)} value={post.drugSunbEtc[0] || ''} type="text" name="drugSunbEtc" className="inp valm" style={{ width: '300px' }} />
                                    <button onClick={() => addSunbValue()} className="btprimary ml5 valm" type="button">상세입력</button>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="3">
                                <div className="pl6">
                                  {drugSunbEtc.map((input, index) => (
                                    <p key={index} style={{ display: 'inline-block' }}>
                                      <input onChange={e => addDrugSunbEtc(e, index)} value={post.drugSunbEtc[index] || ''} type="text" name="drugSunbEtc" className="inp valm" />
                                      <button onClick={addMultipleSunb} type="button" className="schmorebtn on" title="성분 추가"></button>
                                    </p>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          )
                        }
                        {
                          isMultipleSunb == 0 && isMoreSunb === true && (
                            <Fragment>
                              <tr>
                                <th rowSpan="2">함량&#47;단위</th>
                                <td colSpan="3">
                                  <p className="pl6">
                                    <input onChange={writeInput} value={post.volume || ''} type="text" name="volume" className="inp mr5 valm" />
                                    {
                                      post.sunbCondition === '범위' && (
                                        <input onChange={writeInput} value={post.volumeBetween || ''} type="text" name="volumeBetween" className="inp mr5 valm" />
                                      )
                                    }
                                    <select onChange={writeInput} value={post.unit || ''} name="unit" className="valm">
                                      <option value="">선택하세요.</option>
                                      {
                                        (drugSunbCondition !== '' && drugSunbCondition.length > 0) && (
                                          drugSunbCondition.map((data, index) => (
                                            <option key={data} value={data}>{data}</option>
                                          ))
                                        )
                                      }
                                    </select>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <div className="pl6">
                                    <label>
                                      <input onChange={writeInput} type="radio" name="sunbCondition" value="이상" checked={post.sunbCondition === "이상"} />
                                      <span className="ml5">이상</span>
                                    </label>
                                    <label>
                                      <input onChange={writeInput} type="radio" name="sunbCondition" value="이하" checked={post.sunbCondition === "이하"} />
                                      <span className="ml5">이하</span>
                                    </label>
                                    <label>
                                      <input onChange={writeInput} type="radio" name="sunbCondition" value="일치" checked={post.sunbCondition === "일치"} />
                                      <span className="ml5">일치</span>
                                    </label>
                                    <label>
                                      <input onChange={writeInput} type="radio" name="sunbCondition" value="범위" checked={post.sunbCondition === "범위"} />
                                      <span className="ml5">범위</span>
                                    </label>
                                    <button onClick={() => resetSunbValue() } type="button" className="btgray ml10 valm" style={{ width: '60px', padding: '0' }}>초기화</button>
                                  </div>
                                </td>
                              </tr>
                            </Fragment>
                          )
                        }
                        <tr>
                          <th>종류</th>
                          <td colSpan="3">
                            <div className="pl6">
                              <label>
                                <input onChange={writeInput} type="radio" name="drugClass" value="일반" checked={post.drugClass === "일반"} />
                                <span className="ml5">일반</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="drugClass" value="전문" checked={post.drugClass === "전문"} />
                                <span className="ml5">전문</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>KPIC 약효분류</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.kpicAtc || ''} type="text" name="kpicAtc" className="inp" />
                            </p>
                          </td>
                          <th>보건복지부 분류번호</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.clsCode || ''} type="text" name="clsCode" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>효능&#47;효과</th>
                          <td colSpan="3">
                            <div className="pl6">
                              {effect.map((input, index) => (
                                <p key={index} style={{ display: 'inline-block' }}>
                                  <input onChange={e => addEffect(e, index)} value={post.effect[index] || ''} type="text" name="effect" className="inp valm" />
                                  <button onClick={addMultipleEffect} type="button" className="schmorebtn on" title="효능/효과 추가"></button>
                                </p>
                              ))}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>연령금기</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.durAge || ''} type="text" name="durAge" className="inp" />
                            </p>
                          </td>
                          <th>임부금기</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.durPreg || ''} type="text" name="durPreg" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>노인금기</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.durSenior || ''} type="text" name="durSenior" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>단일/복합</th>
                          <td colSpan="3">
                            <div className="pl6">
                              <label>
                                <input onChange={writeInput} type="radio" name="itemIngrType" value="단일" checked={post.itemIngrType === "단일"} />
                                <span className="ml5">단일</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="itemIngrType" value="복합" checked={post.itemIngrType === "복합"} />
                                <span className="ml5">복합</span>
                              </label>
                              <label>
                                <input onChange={writeInput} type="radio" name="itemIngrType" value="" checked={post.itemIngrType === ""} />
                                <span className="ml5">해당없음</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>ATC 코드</th>
                          <td colSpan="3">
                            <div className="pl6">
                              {atcCode.map((input, index) => (
                                <p key={index} style={{ display: 'inline-block' }}>
                                  <input onChange={e => addAtcCode(e, index)} value={post.atcCode[index] || ''} type="text" name="atcCode" className="inp" />
                                  <button onClick={addInput} type="button" className="schmorebtn on" title="atc 코드 추가"></button>
                                </p>
                              ))}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                    <button onClick={save} className="btprimary">저장</button>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Condition;