import { useContext, useState, useRef, useEffect } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import ExportCSV from '../excel/ExportCSV';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import API from '../../utils/API';

function Disease() {
  const BASE_URL = useContext(UrlContext) + `disease`;
  const BLOCK_SIZE = 10;

  const [classification, setClassification] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [diseaseUpdate, setDiseaseUpdate] = useState(new Date());

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);
  const [layerNo, setLayerNo] = useState(0);

  let fileInput = useRef(null);
  const [upFile, setUpFile] = useState();
  const [fileName, setFileName] = useState([]);
  const [createSuccFile, setCreateSuccFile] = useState([]);
  const [createFailFile, setCreateFailFile] = useState([]);
  const [updateSuccFile, setUpdateSuccFile] = useState([]);
  const [updateFailFile, setUpdateFailFile] = useState([]);
  const exportData = useRef([]);
  const [param, setParam] = useState({
    code: '', diseasecode: '', diseasecode3: '', diseasecode4: '', diseasecode5: '', uppercodeYn: ''
    , diseasecodeName: '', diseaseSpecific: '', diseaseGuide: '', diseaseLevel: ''
    , diseaseGuideCategory: '', diseaseReference: '', diseaseUpdate: '', note: ''
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getPostList();
  }, [no]);

  useEffect(() => {
    if (diseaseUpdate !== null) {
      let yyyy = diseaseUpdate.getFullYear();
      let mm = String(diseaseUpdate.getMonth() + 1).padStart(2, '0');
      let dd = String(diseaseUpdate.getDate()).padStart(2, '0');
      setPost({ ...post, ['diseaseUpdate']: yyyy + '-' + mm + '-' + dd });
    } else { setPost({}); }
  }, [diseaseUpdate]);

  const init = () => {
    setDiseaseUpdate(null);
  };

  const getPostList = () => {
    fetch(`${BASE_URL}?classification=${classification}&keyword=${keyword}&page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const writeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') {
      if (no !== 1) {
        setNo(1);
      } else {
        getPostList();
      }
    }
  };

  const uploadable = () => {
    if (fileInput !== null && fileInput.current !== null) {
      if (fileName.length < 3) {
        alert('엑셀 파일을 찾아 등록하세요.'); return false;
      }
    }
    return true;
  };

  const uploadFiles = (e) => {
    setUpFile(e.target.files);
    setFileName(e.target.files[0].name);
  };

  const upload = (e) => {
    e.preventDefault();
    resetUploadData();

    if (uploadable() === true) {
      const formData = new FormData(e.target);
      fetch(`${BASE_URL}/excel`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data !== undefined && data.code !== undefined) {
            if (data.code === '200') {
              if (data.data.createSuccessList.length > 0) {
                setCreateSuccFile(data.data.createSuccessList);
                setPostList([...postList, ...data.data.createSuccessList]);
              }
              if (data.data.createFailList.length > 0) {
                setCreateFailFile(data.data.createFailList);
              }
              if (data.data.updateSuccessList.length > 0) {
                setUpdateSuccFile(data.data.updateSuccessList);
                setPostList([...postList, ...data.data.updateSuccessList]);
              }
              if (data.data.updateFailList.length > 0) {
                setUpdateFailFile(data.data.updateFailList);
              }
            } else { alert(`업로드에 실패하였습니다.\n${data.mesg}`); }
          }
        });
    }
  };

  const reset = () => {
    if (fileInput !== null && fileInput.current !== null) {
      fileInput.current.value = '';
    }
    setFileName([]);
    resetUploadData();
  };

  const resetUploadData = () => {
    setCreateSuccFile([]);
    setCreateFailFile([]);
    setUpdateSuccFile([]);
    setUpdateFailFile([]);
  };

  const showLayer = (e, idx) => {
    e.preventDefault();
    setLayerNo(idx);
    if (layerNo > 0) {
      setPost({});
    } else {
      reset();
    }
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const download = () => {
    return new Promise(function (resolve, reject) {
      if (postList.length > 0) {
        fetch(`${BASE_URL}/excel?classification=${classification}&keyword=${keyword}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data) => {
            console.log(data)
            if (data !== undefined && data.code !== undefined && data.code === '200') {
              exportData.current = data.data;
              resolve(data.data);
            } else {
              alert('엑셀을 다운로드 받지 못했습니다.');
              reject();
            }
          });
      } else {
        alert('다운로드 할 데이터를 먼저 검색해주세요.');
      }
    });
  };

  const save = () => {
    setParam({
      ['idx']: '', ['code']: '', ['diseasecode3']: '', ['diseasecode4']: '', ['diseasecode5']: '', ['uppercodeYn']: ''
      , ['diseasecodeName']: '', ['diseaseSpecific']: '', ['diseaseGuide']: '', ['diseaseLevel']: ''
      , ['diseaseGuideCategory']: '', ['diseaseReference']: '', ['diseaseUpdate']: '', ['note']: '', ['lifeEatingYn']: ''
    });

    let mergeParam = Object.assign(param, post);
    let diseasecodeVal = Object.values(mergeParam)[Object.keys(mergeParam).findIndex(key => key === 'diseasecode')];
    console.log(mergeParam);

    if (diseasecodeVal !== '' && diseasecodeVal.length !== 0 && (diseasecodeVal.length > 2 && diseasecodeVal.length < 6)) {
      fetch(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(mergeParam),
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            const dataKey = data.data.idx;
            const postKey = postList.findIndex(list => list.idx === dataKey);

            if (postKey !== -1) {
              setPostList(
                postList.map(list =>
                  list.idx !== dataKey ? list : data.data
                )
              );
            } else {
              setPostList(post => [...post, data.data]);
            }

            setIsLayer(false);
          } else {
            alert(data.codeMsg);
          }
        });
    } else {
      alert('질병 코드가 3~5글자인지 확인해 주세요.');
      return false;
    }
  };

  const edit = (data) => {
    setPost(data);
    setLayerNo(1);
    setIsLayer(true);
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const remove = (key) => {
    if (key !== undefined && key.length > 0 && window.confirm('질병 정보를 삭제하시겠습니까?') === true) {
      fetch(`${BASE_URL}/${key}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            console.log(data);
            setPostList(postList.filter(post => post.idx !== key));
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="pharmstit" title="질병"/>
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <select onChange={(e) => setClassification(e.target.value)} className="schmenu inp">
                          <option value="">전체</option>
                          <option value="diseasecodeName">분류명</option>
                          <option value="diseasecode">질병코드</option>
                          <option value="diseaseGuide">안내문</option>
                        </select>
                      </th>
                      <td colSpan="2">
                        <input onChange={(writeKeyword)} onKeyPress={(e) => search(e)} value={keyword || ''} type="text" className="inp valm" style={{ width: '277px' }} />
                      </td>
                      <td>
                        <p className="btnlist">
                          <button onClick={(e) => search(e)} type="button" className="btnsch">검색</button>
                          <button onClick={(e) => showLayer(e, 0)} type="button" className="btnexcel">엑셀 업로드</button>
                          <ExportCSV csvData={exportData.current} fileName="질병에 의한 복약안내" fileHeading={param} download={download} />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d_table mt30">
                <table className="dtable" summary="질병 목록 입니다.">
                  <caption>질병 목록</caption>
                  <colgroup>
                    <col width="100" />
                    <col width="225" />
                    <col width="80" />
                    <col width="80" />
                    <col width="*" />
                    <col width="100" />
                    <col width="80" />
                    <col width="140" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">관리코드</th>
                      <th scope="col">분류명</th>
                      <th scope="col">질병코드</th>
                      <th scope="col">특정대상</th>
                      <th scope="col">안내문</th>
                      <th scope="col">안내문 카테고리</th>
                      <th scope="col">추천지수</th>
                      <th scope="col">변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      postList.map(post => (
                        <tr key={post.code} className="ov">
                          <td>{post.code}</td>
                          <td>{post.diseasecodeName}</td>
                          <td>{post.diseasecode}</td>
                          <td>{post.diseaseSpecific}</td>
                          <td>
                            {
                              (post.diseaseGuide !== null && post.diseaseGuide.length > 0)
                                &&
                                (
                                  post.diseaseGuide.split('\n').map((guide, idx) => (
                                    <p key={idx} className="pl6">
                                      {
                                        idx === 0 ? (<strong>{guide}</strong>) : (
                                          <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                        )
                                      }
                                    </p>
                                  ))
                                )
                            }
                          </td>
                          <td>{post.diseaseGuideCategory}</td>
                          <td>{post.diseaseLevel}</td>
                          <td>
                            <button onClick={() => edit(post)} className="tbbtn btprimary">수정</button>
                            <button onClick={() => remove(post.idx)} className="tbbtn btblack">삭제</button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={(e) => showLayer(e, 1)} className="btprimary">등록</button></p>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true && layerNo === 0 ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>파일 업로드</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form onSubmit={upload}>
                      <table className="dvtable" summary="파일 업로드 입니다.">
                      <caption>파일 업로드</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>주의사항</th>
                          <td>
                            <p className="pl6">1. 삽입하려는 테이블에 빈 칸이 있을 경우 null 값으로 데이터 삽입</p>
                            <p className="pl6">2. 첫 번째 행은 컬럼명이어야 함</p>
                            <p className="pl6">3. 엑셀에 비어있는 행이 있을 경우 한 행 전체가 null 값으로 데이터 삽입</p>
                            <p className="pl6">4. 컬럼 순서는 사전 협의 필요 (컬럼에 대한 협의 후 엑셀 form을 내려받기 할 수 있도록 업데이트 예정)</p>
                          </td>
                        </tr>
                        <tr>
                          <th>첨부파일</th>
                          <td>
                            <p className="pl6">
                              <input onChange={uploadFiles} ref={fileInput} id="fileUpload" name="uploadfile" type="file" />
                              <input readOnly value={fileName} type="text" className="inp valm mr5 read" style={{ width: '168px' }}  />
                              <label htmlFor='fileUpload' className="mr5 p0">
                                <span className="btgray valm">찾아보기</span>
                              </label>
                              <button type="submit" className="btprimary valm">업로드</button>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>결과</th>
                          <td>
                            {
                              createSuccFile.length > 0 &&
                              (
                                <p className="pl6">총 <strong>{createSuccFile.length}</strong>개의 데이터가 입력되었습니다.</p>
                              )
                            }
                            {
                              createFailFile.length > 0 &&
                              (
                                <p className="pl6">입력에 실패한 데이터가 <strong>{createFailFile.length}</strong>개 있습니다.</p>
                              )
                            }
                            {
                              updateSuccFile.length > 0 &&
                              (
                                <p className="pl6">총 <strong>{updateSuccFile.length}</strong>개의 데이터가 업데이트 되었습니다.</p>
                              )
                            }
                            {
                              updateFailFile.length > 0 &&
                              (
                                <p className="pl6">업데이트에 실패한 데이터가 <strong>{updateFailFile.length}</strong>개 있습니다.</p>
                              )
                            }
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                  </div>
                </div>
              </div>
              ) : null}
              {isLayer === true && layerNo === 1 ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>질병 등록</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form>
                      <table className="dvtable" summary="질병 등록 입니다.">
                      <caption>질병 등록</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>관리코드</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input readOnly value={post.code || ''} name="code" style={{ width: '300px' }} type="text" className="inp read" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>질병코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseasecode || ''} name="diseasecode" type="text" className="inp" />
                            </p>
                          </td>
                          <th>상위단계안내 배제여부</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.uppercodeYn || ''} name="uppercodeYn" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>특정대상</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseaseSpecific || ''} name="diseaseSpecific" type="text" className="inp" />
                            </p>
                          </td>
                          <th>업데이트</th>
                          <td>
                            <div className="pl6">
                              <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={diseaseUpdate} onChange={e => setDiseaseUpdate(e)} value={post.diseaseUpdate || ''} customInput={
                                <input type="text" className="inp mr10" />
                              } />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>분류명</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseasecodeName || ''} name="diseasecodeName" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>안내문</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <textarea onChange={writeInput} value={post.diseaseGuide || ''} name="diseaseGuide" style={{width:'100%', height:'146px', color: '#777'}}></textarea>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>추천지수</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseaseLevel || ''} name="diseaseLevel" type="text" className="inp" />
                            </p>
                          </td>
                          <th>안내문 카테고리</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseaseGuideCategory || ''} name="diseaseGuideCategory" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>참고자료</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.diseaseReference || ''} name="diseaseReference" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>참고내용</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.note || ''} name="note" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                    <button onClick={save} className="btprimary">저장</button>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disease;