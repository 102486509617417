import HeadTitle from '../common/headTitle';

function ContactDetail() {
  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="charmacist" title="제휴문의" />
            <div className="subst">
              <h3 className="stit">[ 기본정보 ]</h3>
              <div className="dv_table">
                <table className="dvtable" summary="제휴문의 상세 정보 입니다.">
                  <caption>제휴문의</caption>
                  <colgroup>
                    <col width="132" />
                    <col width="*" />
                    <col width="132" />
                    <col width="225" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>구분</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="category" /><span className="ml5">공공기관</span></label>
                          <label className="mr10"><input type="radio" name="category"  /><span className="ml5">기업</span></label>
                          <label className="mr10"><input type="radio" name="category"  /><span className="ml5">개인</span></label>
                          <label className="mr10"><input type="radio" name="category"  /><span className="ml5">기타</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>기관명 &#47; 성명</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'126px'}} />
                        </p>
                      </td>
                      <th>담당자명</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'126px'}} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>전화번호</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'80px'}} /><span>-</span>
                          <input type="text" className="inp valm" style={{width:'80px'}} /><span>-</span>
                          <input type="text" className="inp valm" style={{width:'80px'}} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'126px'}} /><span>@</span>
                          <input type="text" className="inp valm" style={{width:'126px'}} />
                          <select>
                            <option value="0">선택하세요</option>
                            <option value="naver.com">naver.com</option>
                            <option value="hanmail.com">hanmail.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="gmail.com">gmail.com</option>
                            <option value="직접입력">직접입력</option>
                          </select>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>제목</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'50%'}} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>제안내용</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <textarea className="pl10" placeholder="제안할 내용을 입력하세요." style={{width:'50%', height:'83px'}}></textarea>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3 className="stit pt20">[ 관리자 메모 ]</h3>
              <div className="dv_table">
                <table className="dvtable" summary="관리자 메모 입니다.">
                  <caption>관리자 메모</caption>
                  <colgroup>
                    <col width="132" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>홍길동</th>
                      <td>
                        <p className="pl6">
                          이런 내용은 이렇습니다.<br/>저런 내용은 저렇고요.<br/>이러저러해서 저러저러한데,<br/>요렇게 요렇게는 어떠신가요?<br/>그러그러하게 진행해주시면<br/>나머지 내용은 이러 저러 그러한 방식으로 진행할 수 있을 것 같습니다.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="memo">
                          <textarea placeholder="메모할 내용을 입력하세요."></textarea>
                          <button className="btprimary" type="button">등록</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr">
                  <button className="btprimary">등록</button>
                  <button className="btgray">취소</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetail;