import { Link } from 'react-router-dom';

function HeadTitle({ project, title }) {
  return (
    <div className="htitle">
      <h2>{title}</h2>
      <ul>
        {
          {
            'charmacist': <li><Link to="/charmacist/Index">COWORK</Link></li>,
            'pharmstit': <li><Link to="/pharmstit/Index">팜스트잇</Link></li>,
            'yakgorithm': <li><Link to="/yakgorithm/Index">약고리즘</Link></li>,
            'test': <li><Link to="/test/Index">보류</Link></li>
          }[project]
        }
        <li> &gt; {title}</li>
      </ul>
    </div>
  );
}

export default HeadTitle;