import { useContext, useState, useEffect, useRef } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import ExportCSV from '../excel/ExportCSV';

function Ingredient() {
  const BASE_URL = useContext(UrlContext) + `msgdrug`;
  const BLOCK_SIZE = 10;

  const [classification, setClassification] = useState('');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);
  const [layerNo, setLayerNo] = useState(0);

  const fileInput = useRef(null);
  const [upFile, setUpFile] = useState();
  const [fileName, setFileName] = useState([]);
  const [createSuccFile, setCreateSuccFile] = useState([]);
  const [createFailFile, setCreateFailFile] = useState([]);
  const [updateSuccFile, setUpdateSuccFile] = useState([]);
  const [updateFailFile, setUpdateFailFile] = useState([]);
  const exportData = useRef([]);
  const [param, setParam] = useState({
    codeB: '', drugName: '', bohCode: '', hiraSunbCode: '', multiGuide: ''
    , atcCode: '', atcName: '', atc1: '', atc2: '', atc3: '', atc4: '', atc5: ''
    , drugSunbEtc: '', drugClass: '', itemIngrType: '', drugForm: '', reimYn: '', guideType: ''
    , typeAGuide: '', drugSpecific: '', drugLevel: '', guideCategory: '', typeBGuide: '', note: ''
  });

  useEffect(() => {
    getPostList();
  }, [no]);

  const getPostList = () => {
    fetch(`${BASE_URL}?classification=${classification}&keyword=${keyword}&page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
       'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const writeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') {
      if (no !== 1) {
        setNo(1);
      } else {
        getPostList();
      }
    }
  };

  const uploadable = () => {
    if (fileInput !== null && fileInput.current !== null) {
      if (fileName.length < 3) {
        alert('엑셀 파일을 찾아 등록하세요.'); return false;
      }
    }
    return true;
  };

  const uploadFiles = (e) => {
    setUpFile(e.target.files);
    setFileName(e.target.files[0].name);
  };

  const upload = (e) => {
    e.preventDefault();
    resetUploadData();

    if (uploadable() === true) {
      const formData = new FormData(e.target);
      fetch(`${BASE_URL}/excel`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data !== undefined && data.code !== undefined) {
            if (data.code === '200') {
              if (data.data.createSuccessList.length > 0) {
                setCreateSuccFile(data.data.createSuccessList);
                setPostList([...postList, ...data.data.createSuccessList]);
              }
              if (data.data.createFailList.length > 0) {
                setCreateFailFile(data.data.createFailList);
              }
              if (data.data.updateSuccessList.length > 0) {
                setUpdateSuccFile(data.data.updateSuccessList);
                setPostList([...postList, ...data.data.updateSuccessList]);
              }
              if (data.data.updateFailList.length > 0) {
                setUpdateFailFile(data.data.updateFailList);
              }
            } else { alert(`업로드에 실패하였습니다.\n${data.mesg}`); }
          }
        });
    }
  };

  const reset = () => {
    if (fileInput !== null && fileInput.current !== null) {
      fileInput.current.value = '';
    }
    setFileName([]);
    resetUploadData();
  };

  const resetUploadData = () => {
    setCreateSuccFile([]);
    setCreateFailFile([]);
    setUpdateSuccFile([]);
    setUpdateFailFile([]);
  };

  const showLayer = (e, idx) => {
    e.preventDefault();
    setLayerNo(idx);
    if (layerNo > 0) {
      setPost({});
    } else {
      reset();
    }
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const download = () => {
    return new Promise(function (resolve, reject) {
      if (postList.length > 0) {
        fetch(`${BASE_URL}/excel?classification=${classification}&keyword=${keyword}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data) => {
            console.log(data)
            if (data !== undefined && data.code !== undefined && data.code === '200') {
              console.log(data.data);
              exportData.current = data.data;
              resolve(data.data);
            } else {
              alert('엑셀을 다운로드 받지 못했습니다.');
              reject();
            }
          });
      } else {
        alert('다운로드 할 데이터를 먼저 검색해주세요.');
      }
    });
  };

  const save = () => {
    setParam({
      ['idx']: '', ['codeA']: '', ['codeB']: '', ['drugName']: '', ['bohCode']: '', ['hiraSunbCode']: '', ['multiGuide']: ''
      , ['atcCode']: '', ['atcName']: '', ['atc1']: '', ['atc2']: '', ['atc3']: '', ['atc4']: '', ['atc5']: ''
      , ['drugSunbEtc']: '', ['drugClass']: '', ['itemIngrType']: '', ['drugForm']: '', ['reimYn']: '', ['guideType']: ''
      , ['typeAGuide']: '', ['drugSpecific']: '', ['drugLevel']: '', ['guideCategory']: '', ['typeBGuide']: '', ['note']: ''
    });

    let mergeParam = Object.assign(param, post);
    let hiraCodeVal = Object.values(param)[Object.keys(mergeParam).findIndex(key => key === 'hiraSunbCode')];
    console.log(mergeParam);

    if (hiraCodeVal !== '' && hiraCodeVal.length !== 0 && hiraCodeVal.length === 9) {
      fetch(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify(mergeParam),
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            const dataKey = data.data.idx;
            const postKey = postList.findIndex(list => list.idx === dataKey);
    
            if (postKey !== -1) {
              setPostList(
                postList.map(list =>
                  list.idx !== dataKey ? list : data.data
                )
              );
            } else {
              setPostList(post => [...post, data.data]);
            }
            setIsLayer(false);
          } else {
            alert(data.codeMsg);
          }
        });
    } else {
      alert('주성분 코드가 9글자인지 확인해 주세요.');
      return false;
    }
  };
  
  const edit = (data) => {
    setPost(data);
    setLayerNo(1);
    setIsLayer(true);
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const remove = (key) => {
    if (key !== undefined && key > 0 && window.confirm('약품 정보를 삭제하시겠습니까?') === true) {
      fetch(`${BASE_URL}/${key}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            console.log(data);
            setPostList(postList.filter(post => post.idx !== key));
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="pharmstit" title="주성분"/>
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <select onChange={(e) => setClassification(e.target.value)} className="schmenu inp">
                          <option value="">전체</option>
                          <option value="hiraSunbCode">주성분코드</option>
                          <option value="drugSunbEtc">성분 &#47; 함량</option>
                          <option value="atcCode">ATC코드</option>
                          <option value="typeBGuide">B타입 안내문</option>
                        </select>
                      </th>
                      <td colSpan="2">
                        <input onChange={(writeKeyword)} onKeyPress={(e) => search(e)} value={keyword || ''} type="text" className="inp valm" style={{ width: '277px' }} />
                      </td>
                      <td>
                        <p className="btnlist">
                          <button onClick={(e) => search(e)} type="button" className="btnsch">검색</button>
                          <button onClick={(e) => showLayer(e, 0)} type="button" className="btnexcel">엑셀 업로드</button>
                          <ExportCSV csvData={exportData.current} fileName="주성분에 의한 복약안내" fileHeading={param} download={download} />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d_table mt30" style={{ overflow: 'hidden', overflowX: 'scroll' }}>
                <table className="dtable" summary="약품 목록 입니다." style={{ width: '2500px' }}>
                  <caption>약품 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="80" />
                    <col width="225" />
                    <col width="80" />
                    <col width="80" />
                    <col width="100" />
                    <col width="*" />
                    <col width="80" />
                    <col width="120" />
                    <col width="120" />
                    <col width="120" />
                    <col width="120" />
                    <col width="160" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="140" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">주성분코드</th>
                      <th scope="col">안내문 분리</th>
                      <th scope="col">성분 &#47; 함량</th>
                      <th scope="col">ATC코드</th>
                      <th scope="col">특정대상</th>
                      <th scope="col">카테고리</th>
                      <th scope="col">B타입 안내문</th>
                      <th scope="col">추천지수</th>
                      <th scope="col">관리코드A</th>
                      <th scope="col">관리코드B</th>
                      <th scope="col">약품명</th>
                      <th scope="col">보험코드</th>
                      <th scope="col">ATC 코드명</th>
                      <th scope="col">ATC1</th>
                      <th scope="col">ATC2</th>
                      <th scope="col">ATC3</th>
                      <th scope="col">ATC4</th>
                      <th scope="col">전문 &#47; 일반</th>
                      <th scope="col">단일 &#47; 복합</th>
                      <th scope="col">제형</th>
                      <th scope="col">급여 &#47; 비급여</th>
                      <th scope="col">변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.map(post => (
                      <tr key={post.idx} className="ov">
                        <td>{post.hiraSunbCode}</td>
                        <td>{post.multiGuide}</td>
                        <td>{post.drugSunbEtc}</td>
                        <td>{post.atcCode}</td>
                        <td>{post.drugSpecific}</td>
                        <td>{post.guideCategory}</td>
                        <td>
                          {
                            (post.typeBGuide !== null && post.typeBGuide.length > 0)
                              &&
                              (
                                post.typeBGuide.split('\n').map((guide, idx) => (
                                  <p key={idx} className="pl6">
                                    {
                                      <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                    }
                                  </p>
                                ))
                              )
                          }
                        </td>
                        <td>{post.drugLevel}</td>
                        <td>{post.codeA}</td>
                        <td>{post.codeB}</td>
                        <td>{post.drugName}</td>
                        <td>{post.bohCode}</td>
                        <td>{post.atcName}</td>
                        <td>{post.atc1}</td>
                        <td>{post.atc2}</td>
                        <td>{post.atc3}</td>
                        <td>{post.atc4}</td>
                        <td>{post.drugClass}</td>
                        <td>{post.itemIngrType}</td>
                        <td>{post.drugForm}</td>
                        <td>{post.reimYn}</td>
                        <td>
                          <button onClick={() => edit(post)} className="tbbtn btprimary">수정</button>
                          <button onClick={() => remove(post.idx)} className="tbbtn btblack">삭제</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={(e) => showLayer(e, 1)} className="btprimary">등록</button></p>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true && layerNo === 0 ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>파일 업로드</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                  <div className="layerpopin">
                    <div className="dv_table w100">
                      <form onSubmit={upload}>
                        <table className="dvtable" summary="파일 업로드 입니다.">
                        <caption>파일 업로드</caption>
                        <colgroup>
                          <col width="135" />
                          <col width="*" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>주의사항</th>
                            <td>
                              <p className="pl6">1. 삽입하려는 테이블에 빈 칸이 있을 경우 null 값으로 데이터 삽입</p>
                              <p className="pl6">2. 첫 번째 행은 컬럼명이어야 함</p>
                              <p className="pl6">3. 엑셀에 비어있는 행이 있을 경우 한 행 전체가 null 값으로 데이터 삽입</p>
                              <p className="pl6">4. 컬럼 순서는 사전 협의 필요 (컬럼에 대한 협의 후 엑셀 form을 내려받기 할 수 있도록 업데이트 예정)</p>
                            </td>
                          </tr>
                          <tr>
                            <th>첨부파일</th>
                            <td>
                              <p className="pl6">
                                <input onChange={uploadFiles} ref={fileInput} id="fileUpload" name="uploadfile" type="file" />
                                <input readOnly value={fileName} type="text" className="inp valm mr5 read" style={{ width: '168px' }} />
                                <label htmlFor='fileUpload' className="mr5 p0">
                                  <span className="btgray valm">찾아보기</span>
                                </label>
                                <button type="submit" className="btprimary valm">업로드</button>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>결과</th>
                            <td>
                            {
                              createSuccFile.length > 0 &&
                              (
                                <p className="pl6">총 <strong>{createSuccFile.length}</strong>개의 데이터가 입력되었습니다.</p>
                              )
                            }
                            {
                              createFailFile.length > 0 &&
                              (
                                <p className="pl6">입력에 실패한 데이터가 <strong>{createFailFile.length}</strong>개 있습니다.</p>
                              )
                            }
                            {
                              updateSuccFile.length > 0 &&
                              (
                                <p className="pl6">총 <strong>{updateSuccFile.length}</strong>개의 데이터가 업데이트 되었습니다.</p>
                              )
                            }
                            {
                              updateFailFile.length > 0 &&
                              (
                                <p className="pl6">업데이트에 실패한 데이터가 <strong>{updateFailFile.length}</strong>개 있습니다.</p>
                              )
                            }
                            </td>
                          </tr>
                        </tbody>
                        </table>
                      </form>
                    </div>
                    <div className="laybtn pt25">
                      <button onClick={closeLayer} className="btblack">닫기</button>
                    </div>
                  </div>
                </div>
              ) : null}
              {isLayer === true && layerNo === 1 ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>주성분 정보</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                  <div className="layerpopin">
                    <div className="dv_table w100">
                      <form>
                        <table className="dvtable" summary="주성분 정보 입니다.">
                        <caption>주성분 정보</caption>
                        <colgroup>
                          <col width="135" />
                          <col width="*" />
                          <col width="135" />
                          <col width="*" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>관리코드A</th>
                            <td>
                              <p className="pl6">
                                <input readOnly value={post.codeA || ''} name="codeA" type="text" className="inp read" />
                              </p>
                            </td>
                            <th>관리코드B</th>
                            <td>
                              <p className="pl6">
                                <input readOnly value={post.codeB || ''} name="codeB" type="text" className="inp read" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>급여코드 여부</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.bohCode || ''} name="bohCode" type="text" className="inp" />
                              </p>
                            </td>
                            <th>주성분코드(심평원)</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.hiraSunbCode || ''} name="hiraSunbCode" type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>ATC코드</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <input onChange={writeInput} value={post.atcCode || ''} name="atcCode" style={{ width: '300px' }} type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>ATC코드명</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <input onChange={writeInput} value={post.atcName || ''} name="atcName" style={{ width: '300px' }} type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>약품명</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <input onChange={writeInput} value={post.drugName || ''} name="drugName" style={{ width: '300px' }} type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>성분 및 함량</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <textarea onChange={writeInput} defaultValue={post.drugSunbEtc || ''} name="drugSunbEtc" style={{width:'100%', height:'46px', color: '#777'}}></textarea>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>전문 &#47; 일반</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.drugClass || ''} name="drugClass" type="text" className="inp" />
                              </p>
                            </td>
                            <th>단일 &#47; 복합</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.itemIngrType || ''} name="itemIngrType" type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>제형</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.drugForm || ''} name="drugForm" type="text" className="inp" />
                              </p>
                            </td>
                            <th>특정대상</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.drugSpecific || ''} name="drugSpecific" type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>추천지수</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.drugLevel || ''} name="drugLevel" type="text" className="inp" />
                              </p>
                            </td>
                            <th>안내문 카테고리</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={post.guideCategory || ''} name="guideCategory" type="text" className="inp" />
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>A타입 안내문</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <textarea onChange={writeInput} defaultValue={post.typeAGuide || ''} name="typeAGuide" style={{width:'100%', height:'46px', color: '#777'}}></textarea>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>B타입 안내문</th>
                            <td colSpan="3">
                              <p className="pl6">
                                <textarea onChange={writeInput} defaultValue={post.typeBGuide || ''} name="typeBGuide" style={{width:'100%', height:'46px', color: '#777'}}></textarea>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                        </table>
                      </form>
                    </div>
                    <div className="laybtn pt25">
                      <button onClick={closeLayer} className="btblack">닫기</button>
                      <button onClick={save} className="btprimary">저장</button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ingredient;