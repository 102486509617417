import { Link } from 'react-router-dom';
import logo from '../../asset/images/logo_white.png';

function Header() {
  return (
    <div id="header" className="header">
      <div className="headerin">
        <h1 className="logo"><Link to="/"><img src={logo} alt="참약사" /></Link></h1>
        <ul>
          <li><Link to="/login/Index"><i className="fas fa-arrow-right-to-bracket fa-2x"></i><span>로그인</span></Link></li>
          <li><Link to="/charmacist/Index"><i className="fas fa-asterisk fa-2x"></i><span>COWORK</span></Link></li>
          <li><Link to="/pharmstit/Index"><i className="fas fa-note-sticky fa-2x"></i><span>팜스트잇</span></Link></li>
          <li><Link to="/yakgorithm/Index"><i className="fas fa-capsules fa-2x"></i><span>약고리즘</span></Link></li>
          <li><Link to="/test/Index"><i className="fas fa-pause fa-2x"></i><span>보류</span></Link></li>
          <li><Link to="/"><i className="fas fa-wrench fa-2x"></i><span>환경관리</span></Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Header;