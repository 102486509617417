import { useContext, useState, useEffect } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';

function Category() {
  const BASE_URL = useContext(UrlContext) + `category`;
  const BLOCK_SIZE = 10;

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);

  useEffect(() => {
    getPostList();
  }, [no]);

  const getPostList = () => {
    fetch(`${BASE_URL}/all?page=${no}&size=${BLOCK_SIZE}`, {
       method: 'GET',
       headers: {
        'Content-Type': 'application/json',
       }
     })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const showLayer = (e) => {
    e.preventDefault();
    setPost({});
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const save = () => {
    console.log(post);

    fetch(`${BASE_URL}/save`, {
      method: 'POST',
      body: JSON.stringify(post),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          const dataKey = data.data.no;
          const postKey = postList.findIndex(list => list.no === dataKey);

          if (postKey !== -1) {
            setPostList(
              postList.map(list =>
                list.no !== dataKey ? list : data.data
              )
            );
          } else {
            setPostList(post => [...post, data.data]);
          }

          setIsLayer(false);
        } else {
          alert(data.codeMsg);
        }
      });
  };
  
  const edit = (data) => {
    setPost(data);
    setIsLayer(true);
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const remove = (key) => {
    if (key !== undefined && key > 0 && window.confirm('카테고리 정보를 삭제하시겠습니까?') === true) {
      fetch(`${BASE_URL}/delete/${key}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            setPostList(postList.filter(post => post.no !== key));
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="test" title="카테고리"/>
            <div className="subst">
              <div className="d_table">
                <table className="dtable" summary="카테고리 목록 입니다.">
                  <caption>카테고리 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="140" />
                    <col width="*" />
                    <col width="140" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">번호</th>
                      <th scope="col">구분</th>
                      <th scope="col">소분류</th>
                      <th scope="col">변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.map(post => (
                      <tr key={post.no} className="ov">
                        <td>{post.no}</td>
                        <td>{post.classification}</td>
                        <td>{post.smallCategory}</td>
                        <td>
                          <button onClick={() => edit(post)} className="tbbtn btprimary">수정</button>
                          <button onClick={() => remove(post.no)} className="tbbtn btblack">삭제</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={showLayer} className="btprimary">등록</button></p>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>카테고리 정보</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form>
                      <table className="dvtable" summary="카테고리 정보 입니다.">
                      <caption>카테고리 정보</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>상품코드</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input readOnly onChange={writeInput} value={post.no || ''} name="no" style={{ width: '300px' }} type="text" className="inp read" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>구분</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.classification || ''} name="classification" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>소분류</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.smallCategory || ''} name="smallCategory" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                    <button onClick={save} className="btprimary">저장</button>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;