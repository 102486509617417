import HeadTitle from '../common/headTitle';

function Pay() {
  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin" style={{'overflowX': 'scroll'}}>
            <HeadTitle project="charmacist" title="약국별 수금" />
            <div className="subst" style={{'minWidth': '1200px'}}>
              <div className="d_table">
                <table className="dtable" summary="약국별 수금 목록 입니다.">
                  <caption>약국별 수금 목록</caption>
                  <thead>
                    <tr>
                      <th rowSpan="2">약국코드</th>
                      <th rowSpan="2">약국명</th>
                      <th rowSpan="2">사업자 번호</th>
                      <th rowSpan="2">대표자명</th>
                      <th colSpan="4">수금합계 (결제금액 + 수금프로모션)</th>
                      <th colSpan="4">수금프로모션 금액만</th>
                    </tr>
                    <tr>
                      <th scope="col">01.01 ~ 01.15</th>
                      <th scope="col">12월<br />(12.01 ~ 12.31)</th>
                      <th scope="col">11월<br />(11.01 ~ 10.30)</th>
                      <th scope="col">~ 10.31 까지</th>
                      <th scope="col">01.01 ~ 01.31</th>
                      <th scope="col">12.01 ~ 12.31</th>
                      <th scope="col">11.01 ~ 11.30</th>
                      <th scope="col">~ 22.10.31까지</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                    {
                      // postList.map(post => (
                      //   <tr key={post.odr_orderNo} className="ov" onClick={() => goDetail(post.odr_idx, post.odr_orderNo, post.odr_custCode)}>
                      //     <td>{post.odr_idx}</td>
                      //     <td>{post.odr_date}</td>
                      //     <td>{displayStatus(post.odr_status)}</td>
                      //     <td>{post.odr_title}</td>
                      //     <td>{post.odr_count}</td>
                      //     <td>{post.odr_qty}</td>
                      //     <td>{post.odr_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                      //     <td>{post.odr_modDate}</td>
                      //   </tr>
                      // ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pay;