import { Link } from 'react-router-dom';
import login from './Login.module.css';
import logo from '../../asset/images/logo_white.png';

function Index() {
  return (
    <div id="container">
      <div id="contents" style={{background:'#fff'}}>
        <div className={login.clogin}>
          <div className={login.clogo}>
            <Link to="/"><img src={logo} alt="참약사"/></Link>
          </div>
          <div className={login.loginp}>
            <p className={login.logtxt}>참약사에 오신것을 환영합니다.</p>
            <p className="pb5"><input type="text" className={login.inp + " valm"} style={{width:'398px'}} /></p>
            <p><input type="text" className={login.inp + " valm"} style={{width:'398px'}} /></p>
            <p className={login.logbtn}><Link to="/">로그인</Link></p>
            <p className={login.loglink}><Link to="/">비밀번호 찾기</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;