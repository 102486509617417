import axios from 'axios';

const BASE_URL = 'http://svr.charmacist.net';
// const BASE_URL = 'http://192.168.0.53:8070';

const API = axios.create({
  baseURL: BASE_URL,
  timeout: 3000,
  timeoutErrorMessage: 'timeout',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

export default API;