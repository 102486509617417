import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import HeadTitle from './headTitle';
import Menu from '../common/Menu';


function Index({ project }) {
  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project={project} title="프로젝트"/>
            <div className="mainwr">
              <div className="category">
                <Menu project={project} />
              </div>
              <Fragment>
                <div className="card">
                  <h3>당신을 위한<br/>통합업무관리, COWORK</h3>
                  <Link to="/">이동하기</Link>
                </div>
                <div className="card mt20">
                  <h3>의약품 주문은<br/>참스토어에서!</h3>
                  <Link to="/">이동하기</Link>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;