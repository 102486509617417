import { useState, useEffect } from 'react';
import styles from './Pagination.module.css';

function Pagination({ pageNo, setNo, pageTotal, pageSize }) {
  const [cnt, setCnt] = useState(1);
  const [curBlock, setCurBlock] = useState([]);

  useEffect(() => {
    initCnt();
  }, [pageTotal, pageSize]);
  useEffect(() => {
    settingCurBlock();
  }, [pageNo, cnt]);

  const initCnt = () => {
    (pageTotal > 0 && pageSize > 0) ? setCnt(Math.ceil(pageTotal / pageSize)) : setCnt(1);
  };
  const settingCurBlock = () => {
    let block = Math.ceil(pageNo / pageSize);
    let skip = (block - 1) * pageSize;
    let limit = Math.min(cnt - skip, pageSize);
    let arrPage = new Array(cnt).splice(skip, limit);
    for (let i = 0; i < arrPage.length; i++) {
      arrPage[i] = skip + i + 1;
    }
    setCurBlock(arrPage);
  };
  const settingPage = (no) => {
    if (no !== undefined && isNaN(no) === false && no > 1) {
      if (no <= cnt) { setNo(no); }
    } else {
      setNo(1);
    }
  };
  const hasPrevious = () => {
    return pageNo > 1;
  };
  const hasNext = () => {
    return pageNo !== cnt;
  };
  const hasPrevBlock = () => {
    return curBlock.length > 0 && curBlock[0] > 1;
  };
  const hasNextBlock = () => {
    return curBlock[curBlock.length - 1] < cnt;
  };
  const goFirstPage = () => {
    if (hasPrevious()) { settingPage(1); }
  };
  const goLastPage = () => {
    if (hasNext()) { settingPage(Math.ceil(pageTotal / pageSize)); }
  };
  const goPrevBlock = () => {
    if (hasPrevBlock()) {
      settingPage(curBlock[0] - pageSize);
    }
  };
  const goNextBlock = () => {
    if (hasNextBlock()) {
      settingPage(curBlock[curBlock.length - 1] + 1);
    }
  };
  const goPage = (data) => {
    settingPage(data);
  };

  return (
    <div className={styles.stpage + " mt20"}>
      <span><button onClick={goFirstPage} type="button" className={styles.on}>&lt;&lt;</button></span>
      <span><button onClick={goPrevBlock} type="button" className={styles.on}>&lt;</button></span>
      {
        curBlock.map(idx => (
          <button key={idx} onClick={() => { goPage(idx) }} type="button" className={idx === pageNo ? styles.on : ""}>{idx}</button>
        ))
      }
      <span><button onClick={goNextBlock} type="button" className={styles.on}>&gt;</button></span>
      <span><button onClick={goLastPage} type="button" className={styles.on}>&gt;&gt;</button></span>
    </div>
  )
}

export default Pagination;