import { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';


function ConsultDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const NO = location.pathname.split('/')[3];

  const BASE_URL = useContext(UrlContext) + `consult`;

  const [birthday, setBirthday] = useState(new Date());

  const [consult, setConsult] = useState({});
  const [answer, setAnswer] = useState({});

  useEffect(() => {
    init();
    getPost();
  }, []);

  useEffect(() => {
    if (birthday !== null) {
      let yyyy = birthday.getFullYear();
      let mm = String(birthday.getMonth() + 1).padStart(2, '0');
      let dd = String(birthday.getDate()).padStart(2, '0');
      setConsult({ ...consult, ['birthday']: yyyy + '-' + mm + '-' + dd });
    } else { setConsult({}); }
  }, [birthday]);

  const init = () => {
    setBirthday(null);
  }

  const getPost = () => {
    fetch(`${BASE_URL}/${NO}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          console.log(data.data);
          setConsult(data.data);
        }
      });
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    setConsult({ ...consult, [name]: value });
  }

  const save = () => {
    console.log(consult);

    fetch(`${BASE_URL}` , {
      method: 'POST',
      body: JSON.stringify(consult),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        navigate(`/charmacist/Consult`);
        // if (data !== undefined && data.code !== undefined && data.code === '200') {
        //   const dataKey = data.data.idx;
        //   const postKey = postList.findIndex(list => list.idx === dataKey);

        //   if (postKey !== -1) {
        //     setPostList(
        //       postList.map(list => 
        //         list.idx !== dataKey ? list : data.data
        //       )
        //     );
        //   } else {
        //     setPostList(post => [...post, data.data]);
        //   }

        //   setIsLayer(false);
        // } else {
        //   alert(data.codeMsg);
        // }
      });
  }

  const remove = () => {
    if (NO !== undefined && NO > 0 && window.confirm('가입상담 내역을 삭제하시겠습니까?') === true) {
      fetch(`${BASE_URL}/${NO}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          // if (data !== undefined && data.code !== undefined && data.code === '200') {
          //   setPostList(postList.filter(post => post.idx !== key));
          // } else {
          //   alert(data.codeMsg);
          // }
        });
    }
  }

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="charmacist" title="가입상담" />
            <div className="subst">
              <h3 className="stit">[ 기본정보 ]</h3>
              <div className="dv_table">
                <table className="dvtable" summary="가입상담 상세 정보 입니다.">
                  <caption>가입상담</caption>
                  <colgroup>
                    <col width="132" />
                    <col width="*" />
                    <col width="132" />
                    <col width="225" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>구분</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="category" value="신규오픈" checked={consult.category === "신규오픈"} />
                            <span className="ml5">(약국회원)신규오픈</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="category" value="기존운영약국" checked={consult.category === "기존운영약국"} />
                            <span className="ml5">(약국회원)기존운영약국</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="category" value="약사회원" checked={consult.category === "약사회원"} />
                            <span className="ml5">약사회원</span>
                          </label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>성명</th>
                      <td>
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.userName || ''} name="userName" type="text" className="inp valm" style={{ width: '126px' }} />
                        </p>
                      </td>
                      <th>생년월일</th>
                      <td>
                        <div className="pl6">
                          <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={birthday} value={consult.birthday || ''} onChange={e => setBirthday(e)} customInput={
                            <input type="text" className="inp valm" style={{ width: '126px' }} />
                          } />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>전화번호</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.phone || ''} name="phone" type="text" className="inp valm" style={{ width: '50%' }} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.userEmail || ''} name="userEmail" type="text" className="inp valm" style={{ width : '50%' }} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>근무지 (약국)</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.companyName || ''} name="companyName" type="text" className="inp valm" style={{width:'50%'}} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>약국근무년수</th>
                      <td>
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.serviceYear || ''} name="serviceYear" type="text" className="inp valm" style={{width:'80px'}} /><span>년</span>
                        </p>
                      </td>
                      <th>대표약사여부</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="leader" value="예" checked={consult.leader === "예"} />
                            <span className="ml5">예</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="leader" value="아니오" checked={consult.leader === "아니오"} />
                            <span className="ml5">아니오</span>
                          </label>
                        </p>
                      </td>
                    </tr>
                    {
                      (consult.leader !== null && consult.leader === "예") &&
                        (
                          <tr>
                            <th>약국 주소</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={consult.companyAddress || ''} name="companyAddress" type="text" className="inp valm" style={{ width: '50%' }} />
                              </p>
                            </td>
                            <th>약국 연락처</th>
                            <td>
                              <p className="pl6">
                                <input onChange={writeInput} value={consult.companyPhone || ''} name="companyPhone" type="text" className="inp valm" style={{ width: '126px' }} />
                              </p>
                            </td>
                          </tr>
                        )
                    }
                    <tr>
                      <th>개국 관련 계획</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="plan" value="당장 개국할 계획이 있다" checked={consult.plan === "당장 개국할 계획이 있다"} />
                            <span className="ml5">당장 개국할 계획이 있다</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="plan" value="1년 이내에 개국할 계획이 있다" checked={consult.plan === "1년 이내에 개국할 계획이 있다"} />
                            <span className="ml5">1년 이내에 개국할 계획이 있다</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="plan" value="언젠가는 개국할 생각이 있다" checked={consult.plan === "언젠가는 개국할 생각이 있다"} />
                            <span className="ml5">언젠가는 개국할 생각이 있다</span>
                          </label>
                          <label className="mr10">
                            <input onChange={writeInput} type="radio" name="plan" value="개국할 생각이 없다" checked={consult.plan === "개국할 생각이 없다"} />
                            <span className="ml5">개국할 생각이 없다</span>
                          </label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>상담 참고 내용</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input onChange={writeInput} value={consult.etc || ''} name="etc" type="text" className="inp valm" style={{ width: '50%' }} />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h3 className="stit pt20">[ 관리자 메모 ]</h3>
              <div className="dv_table">
                <table className="dvtable" summary="관리자 메모 입니다.">
                  <caption>관리자 메모</caption>
                  <colgroup>
                    <col width="132" />
                    <col width="*" />
                    <col width="132" />
                    <col width="225" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>구분</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="category" /><span className="ml5">뉴본 (Newbone)</span></label>
                          <label className="mr10"><input type="radio" name="category"  /><span className="ml5">리본 (Rebone)</span></label>
                        </p>
                      </td>
                      <th>양도양수</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isCategory" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isCategory"  /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>약사면허번호</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'126px'}} />
                        </p>
                      </td>
                      <th>VIP 약사</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isVIP" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isVIP"  /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>가입경로</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="how" /><span className="ml5">홈페이지</span></label>
                          <label className="mr10"><input type="radio" name="how" /><span className="ml5">내부 소개</span></label>
                          <label className="mr10"><input type="radio" name="how" /><span className="ml5">유선 연락</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>추천인</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'126px'}} />
                        </p>
                      </td>
                      <th>복지몰 포인트</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isPoint" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isPoint" /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>회원계약서여부</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isAgreement" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isAgreement" /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>가입비납부여부</th>
                      <td>
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isFee" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isFee" /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                      <th>총 납부액</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{ width: '126px' }} /><span>원</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>분할납부</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{ width: '168px' }} /><span>원</span>
                          <button type="button" className="schmorebtn on" title="추가"></button>
                        </p>
                        <p className="pl6 mt5">
                          <input type="text" className="inp valm" style={{ width: '168px' }} /><span>원</span>
                          <button type="button" className="schmorebtn on" title="추가"></button>
                        </p>
                      </td>
                      <th>남은금액</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{ width: '126px' }} /><span>원</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>가입비할인여부</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <label className="mr10"><input type="radio" name="isFeeDiscount" /><span className="ml5">예</span></label>
                          <label className="mr10"><input type="radio" name="isFeeDiscount" /><span className="ml5">아니오</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>가입비할인사유</th>
                      <td colSpan="3">
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'50%'}} />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>비고</th>
                      <td colSpan="3">
                        <div className="memo">
                          <textarea placeholder="메모할 내용을 입력하세요." defaultValue="이런 내용은 이렇습니다. 저런 내용은 저렇고요. 이러저해서 저러저러한데, 요렇게 요렇게는 어떠신가요? 그러그러하게 진행해주시면 나머지 내용은 이러 저러 그러한 방식으로 진행할 수 있을 것 같습니다."></textarea>
                          <button className="btblack" type="button">삭제</button>
                        </div>
                        <div className="memo">
                          <textarea placeholder="메모할 내용을 입력하세요."></textarea>
                          <button className="btprimary" type="button">등록</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="dr_table pt20">
                <table className="drtable" summary="업무 현황입니다.">
                  <caption>업무현황</caption>
                  <colgroup>
                    <col width="132" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>현황</th>
                      <td>
                        <p className="pl6">
                          {/* <label className="mr10"><input type="radio" name="status" defaultChecked /><span className="ml5">확정</span></label> */}
                          <label className="mr10"><input type="radio" name="status" /><span className="ml5">진행중</span></label>
                          <label className="mr10"><input type="radio" name="status" /><span className="ml5">종료</span></label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>사유 메모</th>
                      <td>
                        <p className="pl6">
                          <input type="text" className="inp valm" style={{width:'50%'}} />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                {
                  NO !== undefined &&
                  (
                    <p className="bfl">
                      <button onClick={remove} className="btblack">삭제</button>
                    </p>
                  )
                }
                <p className="bfr">
                  <button onClick={save} className="btprimary">등록</button>
                  <button className="btgray">취소</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultDetail;