import { Link } from 'react-router-dom';

function Menu({ project }) {
  switch (project) {
    case 'charmacist':
      return (
        <ul>
          <li>
            <Link to="/charmacist/Consult">
              <i className="fas fa-house-medical fa-3x"></i><span>가입상담</span>
            </Link>
          </li>
          <li>
            <Link to="/charmacist/Order">
              <i className="fas fa-dolly fa-3x"></i><span>초도주문</span>
            </Link>
          </li>
          <li>
            <Link to="/charmacist/Balance">
              <i className="fas fa-piggy-bank fa-3x"></i><span>약국별 잔고현황</span>
            </Link>
          </li>
          <li>
            <Link to="/charmacist/VIP">
              <i className="fas fa-money-bill-transfer fa-3x"></i><span>VIP 결제 입금액</span>
            </Link>
          </li>
          <li>
            <Link to="/charmacist/Pay">
              <i className="fas fa-hand-holding-dollar fa-3x"></i><span>약국별 수금</span>
            </Link>
          </li>
        </ul>
      );
    case 'pharmstit':
      return (
        <ul>
          <li>
            <Link to="/pharmstit/Disease">
              <i className="fas fa-disease fa-3x"></i><span>질병</span>
            </Link>
          </li>
          <li>
            <Link to="/pharmstit/Ingredient">
              <i className="fas fa-dna fa-3x"></i><span>주성분</span>
            </Link>
          </li>
          <li>
            <Link to="/pharmstit/HiraSunbToBoh">
              <i className="fas fa-copy fa-3x"></i><span>안내문 분리</span>
            </Link>
          </li>
          <li>
            <Link to="/pharmstit/MedGuide">
              <i className="fas fa-notes-medical fa-3x"></i><span>복약 안내문 생성</span>
            </Link>
          </li>
          <li>
            <Link to="/pharmstit/Drug">
              <i className="fas fa-flask fa-3x"></i><span>의약품</span>
            </Link>
          </li>
        </ul>
      );
    case 'yakgorithm':
      return (
        <ul>
          <li>
            <Link to="/yakgorithm/Condition">
              <i className="fas fa-circle-question fa-3x"></i><span>솔루션 조건관리</span>
            </Link>
          </li>
          <li>
            <Link to="/yakgorithm/After">
              <i className="fa-solid fa-wand-magic-sparkles fa-3x"></i><span>후처리</span>
            </Link>
          </li>
          <li>
            <Link to="/yakgorithm/Algorithm">
              <i className="fas fa-arrows-turn-to-dots fa-3x"></i><span>의약품 조건검색</span>
            </Link>
          </li>
        </ul>
      );
    case 'test':
      return (
        <ul>
          <li>
            <Link to="/test/Product">
              <i className="fas fa-box fa-3x"></i><span>제품</span>
            </Link>
          </li>
          <li>
            <Link to="/test/Category">
              <i className="fas fa-network-wired fa-3x"></i><span>제품 카테고리</span>
            </Link>
          </li>
          <li>
            <Link to="/test/Barcode">
              <i className="fas fa-barcode fa-3x"></i><span>바코드</span>
            </Link>
          </li>
          <li>
            <Link to="/test/DrugToBarcode">
              <i className="fas fa-code-commit fa-3x"></i><span>코드 매핑</span>
            </Link>
          </li>
          <li>
            <Link to="/test/Contact">
              <i className="fas fa-comment-medical fa-3x"></i><span>제휴문의</span>
            </Link>
          </li>
        </ul>
      );
    default:
      return null;
  }
}

export default Menu;