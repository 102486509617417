import { useContext, useState, useEffect } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

function Barcode() {
  const BASE_URL = useContext(UrlContext) + `barcode`;
  const BLOCK_SIZE = 10;

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [itemPermitDate, setItemPermitDate] = useState(new Date());
  const [cancelDate, setCancelDate] = useState(new Date());

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getPostList();
  }, [no]);

  useEffect(() => {
    if (itemPermitDate !== null) {
      let yyyy = itemPermitDate.getFullYear();
      let mm = String(itemPermitDate.getMonth() + 1).padStart(2, '0');
      let dd = String(itemPermitDate.getDate()).padStart(2, '0');
      setPost({ ...post, ['itemPermitDate']: yyyy + '-' + mm + '-' + dd });
    }
  }, [itemPermitDate]);

  useEffect(() => {
    if (cancelDate !== null) {
      let yyyy = cancelDate.getFullYear();
      let mm = String(cancelDate.getMonth() + 1).padStart(2, '0');
      let dd = String(cancelDate.getDate()).padStart(2, '0');
      setPost({ ...post, ['cancelDate']: yyyy + '-' + mm + '-' + dd });
    }
  }, [cancelDate]);

  const init = () => {
    setItemPermitDate(null);
    setCancelDate(null);
  }

  const getPostList = () => {
    fetch(`${BASE_URL}/all?page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const showLayer = (e) => {
    e.preventDefault();
    setPost({});
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const save = () => {
    console.log(post);

    fetch(`${BASE_URL}/save`, {
      method: 'POST',
      body: JSON.stringify(post),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          const dataKey = data.data.idx;
          const postKey = postList.findIndex(list => list.idx === dataKey);

          if (postKey !== -1) {
            setPostList(
              postList.map(list =>
                list.idx !== dataKey ? list : data.data
              )
            );
          } else {
            setPostList(post => [...post, data.data]);
          }

          setIsLayer(false);
        } else {
          alert(data.codeMsg);
        }
      });
  };

  const edit = (data) => {
    setPost(data);
    setIsLayer(true);
  };

  const writeInput = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const remove = (key) => {
    if (key !== undefined && key.length > 0 && window.confirm('바코드 정보를 삭제하시겠습니까?') === true) {
      fetch(`${BASE_URL}/delete/${key}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            console.log(data);
            setPostList(postList.filter(post => post.idx !== key));
          } else {
            alert(data.codeMsg);
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="yakgorithm" title="바코드"/>
            <div className="subst">
              <div className="d_table">
                <table className="dtable" summary="바코드 목록 입니다.">
                  <caption>바코드 목록</caption>
                  <colgroup>
                    <col width="120" />
                    <col width="*" />
                    <col width="100" />
                    <col width="120" />
                    <col width="80" />
                    <col width="80" />
                    <col width="120" />
                    <col width="140" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">회사명</th>
                      <th scope="col">제품명</th>
                      <th scope="col">업체명</th>
                      <th scope="col">성분</th>
                      <th scope="col">단위</th>
                      <th scope="col">제형</th>
                      <th scope="col">비고</th>
                      <th scope="col">변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.map(post => (
                      <tr key={post.idx} className="ov">
                        <td>{post.upsoName}</td>
                        <td>{post.drugName}</td>
                        <td>{post.upsoName}</td>
                        <td>{post.drugSize}</td>
                        <td>{post.drugAmount}</td>
                        <td>{post.form}</td>
                        <td>{post.remark}</td>
                        <td>
                          <button onClick={() => edit(post)} className="tbbtn btprimary">수정</button>
                          <button onClick={() => remove(post.idx)} className="tbbtn btblack">삭제</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={(e) => showLayer(e, 1)} className="btprimary">등록</button></p>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>약품 상세 정보</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form>
                      <table className="dvtable" summary="약품 상세 정보 입니다.">
                      <caption>약품 상세 정보</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>인덱스</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input readOnly onChange={writeInput} value={post.idx || ''} name="idx" style={{ width: '300px' }} type="text" className="inp read" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>제품명</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.drugName || ''} name="drugName" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>업체명</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.upsoName || ''} name="upsoName" type="text" className="inp" />
                            </p>
                          </td>
                          <th>성분</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.drugSize || ''} name="drugSize" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>단위</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.drugAmount || ''} name="drugAmount" type="text" className="inp" />
                            </p>
                          </td>
                          <th>제형</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.form || ''} name="form" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>포장단위</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.drugPackage || ''} name="drugPackage" type="text" className="inp" />
                            </p>
                          </td>
                          <th>식약처 품목기준코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.kfdaItemSeq || ''} name="kfdaItemSeq" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>허가일자</th>
                          <td>
                            <div className='pl6'>
                              <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={itemPermitDate} onChange={e => setItemPermitDate(e)} customInput={
                                <input type="text" className="inp" />
                              } />
                            </div>
                          </td>
                          <th>전문 &#47; 일반</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.drugClass || ''} name="drugClass" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>대표코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.rpCode || ''} name="rpCode" type="text" className="inp" />
                            </p>
                          </td>
                          <th>표준코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.sdCode || ''} name="sdCode" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>보험급여코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.ediCode || ''} name="ediCode" type="text" className="inp" />
                            </p>
                          </td>
                          <th>심평원 주성분코드</th>
                          <td>
                            <p className="pl6">
                              <input onChange={writeInput} value={post.hiraSunbCode || ''} name="hiraSunbCode" type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>비고</th>
                          <td colSpan="3">
                            <p className="pl6">
                              <input onChange={writeInput} value={post.remark || ''} name="remark" style={{ width: '300px' }} type="text" className="inp" />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>취하일자</th>
                          <td colSpan="3">
                            <div className="pl6">
                              <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={cancelDate} onChange={e => setCancelDate(e)} customInput={
                                <input type="text" className="inp" />
                              } />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                    <button onClick={save} className="btprimary">저장</button>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Barcode;