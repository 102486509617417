import { useContext, useState, useEffect, useRef } from 'react';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

function HiraSunbToBoh() {
  const BASE_URL = useContext(UrlContext) + `pharmstit`;
  const [blockSize, setBlockSize] = useState(10);
  const [sizeVal, setSizeVal] = useState(10);

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [bohCode, setBohCode] = useState('');
  const [hiraSunbCode, setHiraSunbCode] = useState('');
  const [check, setCheck] = useState('');
  const [multiGuide, setMultiGuide] = useState('');
  const [wdateAfter, setWdateAfter] = useState('');
  const [wdateBefore, setWdateBefore] = useState('');

  const [date, setDate] = useState({});

  const [postList, setPostList] = useState([]);
  const originPostList = useRef([]);

  const [checkedList, setCheckedList] = useState([]);
  const [checkedTypeList, setCheckedTypeList] = useState([]);

  useEffect(() => {
    getPostList();
  }, [no, blockSize]);

  useEffect(() => {
    if (wdateAfter !== null && wdateAfter !== '') {
      setDate(date => {
        let yyyy = wdateAfter.getFullYear();
        let mm = String(wdateAfter.getMonth() + 1).padStart(2, '0');
        let dd = String(wdateAfter.getDate()).padStart(2, '0');
        return { ...date, 'wdateAfter': yyyy + '-' + mm + '-' + dd };
      });
    } else {
      setDate(prevDate => {
        return { ...prevDate, 'wdateAfter': '' };
      });
    }
  }, [wdateAfter]);
  
  useEffect(() => {
    if (wdateBefore !== null && wdateBefore !== '') {
      setDate(date => {
        let yyyy = wdateBefore.getFullYear();
        let mm = String(wdateBefore.getMonth() + 1).padStart(2, '0');
        let dd = String(wdateBefore.getDate()).padStart(2, '0');
        return { ...date, 'wdateBefore': yyyy + '-' + mm + '-' + dd };
      });
    } else {
      setDate(prevDate => {
        return { ...prevDate, 'wdateBefore': '' };
      });
    }
  }, [wdateBefore]);

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') {
      if (blockSize !== sizeVal) {
        setBlockSize(sizeVal);
      } else { getPostList();  }
      if (no !== 1) {
        setNo(1);
      }
    }
  };

  const getPostList = () => {
    let param = {
      bohCode: bohCode, hiraSunbCode: hiraSunbCode, check: check, multiGuide: multiGuide, wdateAfter: wdateAfter, wdateBefore: wdateBefore,
    };
    let mergeParam = Object.assign(param, date);
    let queryParams = Object.keys(mergeParam).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(mergeParam[k])).join('&');

    fetch(`${BASE_URL}?${queryParams}&page=${no}&size=${blockSize}`, {
      method: 'GET',
      headers: {
       'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
          const originData = JSON.parse(JSON.stringify(data.data));
          originPostList.current = [...originData];
        }
      });
  };

  const checkedAllHandler = (checked) => {
    if (checked) {
      const arrIdx = postList.map(obj => obj.idx);
      setCheckedList([...checkedList].concat(arrIdx));
    } else {
      setCheckedList([]);
    }
  };

  const checkedSingleHandler = (data, checked, idx) => {
    if (checked) {
      setCheckedList(checkedList => [...checkedList, idx]);
      setCheckedTypeList(checkedTypeList => [...checkedTypeList, data.check])
    } else {
      setCheckedList(checkedList.filter((obj) => obj !== idx));
      setCheckedTypeList(checkedTypeList.filter((obj) => obj !== data.check));
    }
  };

  const checkedChange = (type) => {
    if (checkedList.length > 0) {
      if (type !== undefined) {
        if (type === 'create') {
          if (window.confirm('검토를 일괄 생성하시겠습니까?') !== true) {
            return false;
          }
        } else if (type === 'delete') {
          if (window.confirm('검토를 일괄 삭제하시겠습니까?') !== true) {
            return false;
          }
        }

        const strCheckedList = checkedList.map((obj) => obj.toString());
        const formattedTypeList = checkedTypeList.map((obj) => {
          if (obj === '생성검토') { return 'create'; }
          else if (obj === '삭제검토') { return 'delete'; }
          else if (obj === 'N') { return 'complete'; }
          else { return obj; }
        });

        const firstVal = formattedTypeList[0];
        const compareVal = formattedTypeList.map((value) => value === firstVal);
        const everyVal = compareVal.every((value) => value === true);

        if (everyVal) {
          if (firstVal === type) {
            fetch(`${BASE_URL}/check`, {
              method: 'POST',
              body: JSON.stringify({ data: strCheckedList, type: type }),
              headers: {
                'Content-Type': 'application/json',
              }
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                throw response;
              })
              .then((data) => {
                console.log(data);
                if (data !== undefined && data.code !== undefined && data.code === '200') {
                  alert('수정이 완료되었습니다.');
                  getPostList();
                } else {
                  alert('데이터를 수정하던 중 오류가 발생했습니다.\n다시 시도해 주세요.'); return false;
                }
              });
          } else {
            if (firstVal === 'complete') {
              alert('이 안내문은 이미 검토가 완료되어 생성 혹은 삭제가 불가능합니다.'); return false;
            }
            if (type === 'create') {
              alert('이 안내문은 삭제 요청만 가능합니다.'); return false;
            } else if (type === 'delete') {
              alert('이 안내문은 생성 요청만 가능합니다.'); return false;
            }
          }
        } else {
          alert('검토할 안내문의 요청 타입을 통일해 주세요.'); return false;
        }
      }
    } else {
      alert('검토를 하나 이상 체크해 주세요.'); return false;
    }
  };

  const edit = (e) => {
    if (window.confirm('안내문 분리를 일괄 수정하시겠습니까?') === true) {
      console.log(postList);
      const updatePosts = postList.filter((post, idx) => {
        const originPost = originPostList.current[idx];
        return Object.keys(post).some(key => post[key] !== originPost[key]);
      });
      const editGuide = updatePosts.map((post) => ({ idx: post.idx, multiGuide: post.multiGuide }));
  
      fetch(`${BASE_URL}`, {
        method: 'POST',
        body: JSON.stringify({ data: editGuide }),
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            alert('일괄 수정이 완료되었습니다.');
          } else {
            alert('데이터를 수정하던 중 오류가 발생했습니다.\n다시 시도해 주세요.');
          }
        });
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="pharmstit" title="안내문 분리" />
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>보험코드</th>
                      <td>
                        <input onChange={e => setBohCode(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>주성분코드(심평원)</th>
                      <td> 
                        <input onChange={e => setHiraSunbCode(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>검토</th>
                      <td>
                        <input onChange={e => setCheck(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                    </tr>
                    <tr>
                      <th>안내문 분리</th>
                      <td>
                        <input onChange={e => setMultiGuide(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>변경일자</th>
                      <td colSpan="3">
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={wdateAfter}
                          onChange={e => {setWdateAfter(e)}}
                          customInput={
                            <input className="inp valm mr5" type="text" style={{ width: '168px' }} />
                          }
                        />
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={wdateBefore}
                          onChange={e => {setWdateBefore(e)}}
                          customInput={
                            <input className="inp valm mr10" type="text" style={{ width: '168px' }} />
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>&nbsp;</th>
                      <td colSpan="5">
                        <p className="btnlist">
                          <input
                            onChange={(e) => setSizeVal(e.target.value)}
                            type="text"
                            className="inp valm mr10"
                            value={sizeVal}
                          />
                          <span className="valm" style={{ fontSize: '12px' }}>개씩</span>
                          <button onClick={(e) => search(e)} className="btnsch ml20" type="button">검색</button>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d_table mt30">
                <table className="dtable" summary="약품 목록 입니다.">
                  <caption>약품 목록</caption>
                  <colgroup>
                    <col width="200" />
                    <col width="200" />
                    <col width="*" />
                    <col width="*" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">보험코드</th>
                      <th scope="col">주성분코드</th>
                      <th scope="col">
                        검토
                        <input
                          onChange={e => checkedAllHandler(e.target.checked)}
                          type="checkbox"
                          className="ml10"
                        />
                      </th>
                      <th scope="col">안내문 분리</th>
                      <th scope="col">변경일자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      postList.length === 0 && (
                        <tr>
                          <td colSpan="5">검색 내역이 없습니다.</td>
                        </tr>
                      )
                    }
                    {
                      postList.length > 0 && (
                        postList.map((post, index) => (
                          <tr key={post.idx} className="ov">
                            <td>{post.bohCode}</td>
                            <td>{post.hiraSunbCode}</td>
                            <td>
                              {post.check}
                              <input
                                onChange={e => checkedSingleHandler(post, e.target.checked, post.idx)}
                                checked={checkedList.includes(post.idx) ? true : false}
                                type="checkbox"
                                className="ml10"
                              />
                            </td>
                            <td>
                              <input
                                onChange={e => {
                                  const newPostList = [...postList];
                                  newPostList[index].multiGuide = e.target.value;
                                  setPostList(newPostList);
                                }}
                                value={post.multiGuide}
                                type="text"
                                name="multiGuide"
                                className="inp valm"
                              />
                            </td>
                            <td>{post.wdate}</td>
                          </tr>
                        ))
                      )
                    }
                  </tbody>
                  {
                    postList.length > 0 && (
                      <tfoot>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <button onClick={e => checkedChange('create')} type="button" className="tbbtn btsecondary">일괄생성</button>
                            <button onClick={e => checkedChange('delete')} type="button" className="tbbtn btred">일괄삭제</button>
                          </td>
                          <td>
                            <button onClick={e => edit()} type="button" className="tbbtn btsecondary">일괄수정</button>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      </tfoot>
                    )
                  }
                </table>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={blockSize}></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HiraSunbToBoh;