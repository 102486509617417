import HeadTitle from '../common/headTitle';

function VIP() {
  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin" style={{'overflowX': 'scroll'}}>
            <HeadTitle project="charmacist" title="VIP 결제 입금액" />
            <div className="subst" style={{'minWidth': '1400px'}}>
              <div className="d_table">
                <table className="dtable" summary="VIP 결제 입금액 목록 입니다.">
                  <caption>VIP 결제 입금액 목록</caption>
                  <thead>
                    <tr>
                      <th>약국코드</th>
                      <th>약국명</th>
                      <th>01월 말 잔고</th>
                      <th>01월 주문금액</th>
                      <th>12/16 잔고</th>
                      <th>12/16 이후 주문액</th>
                      <th>12월 말 잔고</th>
                      <th>12/16 이전 주문</th>
                      <th>1월 결제 + 수금프로</th>
                      <th>1/1 이후 결제액</th>
                      <th>01/1 이후 수금프로</th>
                      <th>1.8% 해당하는 결제금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    <tr className="ov">
                      <td>5100000</td>
                      <td>참약사 약국</td>
                      <td className="red num">-100,204,534</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                      <td className="num">15,646,557</td>
                    </tr>
                    {
                      // postList.map(post => (
                      //   <tr key={post.odr_orderNo} className="ov" onClick={() => goDetail(post.odr_idx, post.odr_orderNo, post.odr_custCode)}>
                      //     <td>{post.odr_idx}</td>
                      //     <td>{post.odr_date}</td>
                      //     <td>{displayStatus(post.odr_status)}</td>
                      //     <td>{post.odr_title}</td>
                      //     <td>{post.odr_count}</td>
                      //     <td>{post.odr_qty}</td>
                      //     <td>{post.odr_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                      //     <td>{post.odr_modDate}</td>
                      //   </tr>
                      // ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VIP;