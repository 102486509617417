import { useContext, useState, useEffect } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';

function Drug() {
  const BASE_URL = useContext(UrlContext) + `drug`;
  const BLOCK_SIZE = 10;

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);

  useEffect(() => {
    getPostList();
  }, [no]);

  const getPostList = () => {
    fetch(`${BASE_URL}/all?page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const showLayer = (e) => {
    e.preventDefault();
    setIsLayer(true);
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const detail = (idx) => {
    fetch(`${BASE_URL}/detail/${idx}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setIsLayer(true);
        setPost(data.data);
      });
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="pharmstit" title="의약품"/>
            <div className="subst">
              <div className="d_table">
                <table className="dtable" summary="의약품 목록 입니다.">
                  <caption>의약품 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="80" />
                    <col width="200" />
                    <col width="130" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">제형</th>
                      <th scope="col">제형</th>
                      <th scope="col">제품명</th>
                      <th scope="col">회사명</th>
                      <th scope="col">약가</th>
                      <th scope="col">성상</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.map(post => (
                      <tr key={post.idx} onClick={() => { detail(post.idx) }} className="ov">
                        <td>{post.drugClass}</td>
                        <td>{post.drugForm}</td>
                        <td>{post.drugName}</td>
                        <td>{post.upsoName}</td>
                        <td>{post.listBoh}</td>
                        <td>{post.charact}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="bbtn">
                <p className="bfr"><button onClick={(e) => showLayer(e, 1)} className="btprimary">등록</button></p>
              </div> */}
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {isLayer === true ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>약품 상세 정보</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  <div className="dv_table w100">
                    <form>
                      <table className="dvtable" summary="약품 상세 정보 입니다.">
                      <caption>약품 상세 정보</caption>
                      <colgroup>
                        <col width="135" />
                        <col width="*" />
                        <col width="135" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>제품명</th>
                          <td><p className="pl6">{post.drugName}</p></td>
                          <th>제형</th>
                          <td><p className="pl6">{post.drugForm}</p></td>
                        </tr>
                        <tr>
                          <th>전문 &#47; 일반</th>
                          <td><p className="pl6">{post.drugClass}</p></td>
                          <th>단일 &#47; 복합</th>
                          <td><p className="pl6">{post.itemIngrType}</p></td>
                        </tr>
                        <tr>
                          <th>성분 &#47; 함량</th>
                          <td colSpan="3"><p className="pl6">{post.drugSunbEtc}</p></td>
                        </tr>
                        <tr>
                          <th>효능 &#47; 효과</th>
                          <td colSpan="3"><p className="pl6">{post.effect}</p></td>
                        </tr>
                        <tr>
                          <th>ATC 코드</th>
                          <td><p className="pl6">{post.atcCode}</p></td>
                          <th>KPIC 약효분류</th>
                          <td><p className="pl6">{post.KpicAtc}</p></td>
                        </tr>
                        <tr>
                          <th>보건복지부 분류번호</th>
                          <td><p className="pl6">{post.clsCode}</p></td>
                          <th>약품코드</th>
                          <td><p className="pl6">{post.drugCode}</p></td>
                        </tr>
                        <tr>
                          <th>연령금기</th>
                          <td colSpan="3"><p className="pl6">{post.durAge}</p></td>
                        </tr>
                        <tr>
                          <th>임부금기</th>
                          <td colSpan="3"><p className="pl6">{post.durPreg}</p></td>
                        </tr>
                        <tr>
                          <th>노인주의</th>
                          <td colSpan="3"><p className="pl6">{post.durSenior}</p></td>
                        </tr>
                        <tr>
                          <th>식약처 품목기준코드</th>
                          <td><p className="pl6">{post.itemSeq}</p></td>
                          <th>보험급여코드</th>
                          <td><p className="pl6">{post.bohCode}</p></td>
                        </tr>
                        <tr>
                          <th>심평원 주성분코드</th>
                          <td><p className="pl6">{post.hiraSunbCode}</p></td>
                          <th>영문 제품명</th>
                          <td><p className="pl6">{post.drugEname}</p></td>
                        </tr>
                        <tr>
                          <th>제조 &#47; 수입사</th>
                          <td><p className="pl6">{post.upsoName}</p></td>
                          <th>보험급여정보</th>
                          <td><p className="pl6">{post.listBoh}</p></td>
                        </tr>
                        <tr>
                          <th>성상</th>
                          <td colSpan="3"><p className="pl6">{post.charact}</p></td>
                        </tr>
                        <tr>
                          <th>저장방법</th>
                          <td colSpan="3"><p className="pl6">{post.stmt}</p></td>
                        </tr>
                        <tr>
                          <th>용법 &#47; 용량</th>
                          <td colSpan="3"><p className="pl6 scr" dangerouslySetInnerHTML={{ __html: post.dosage }}></p></td>
                        </tr>
                        <tr>
                          <th>약효요약</th>
                          <td colSpan="3"><p className="pl6">{post.indicMedi}</p></td>
                        </tr>
                        <tr>
                          <th>복약정보</th>
                          <td colSpan="3"><p className="pl6">{post.genMedi}</p></td>
                        </tr>
                        <tr>
                          <th>식별사진이미지</th>
                          <td><p className="pl6">{post.idfyImg}</p></td>
                          <th>포장이미지</th>
                          <td><p className="pl6">{post.packImg}</p></td>
                        </tr>
                        <tr>
                          <th>업데이트 구분</th>
                          <td><p className="pl6">{post.flag}</p></td>
                          <th>투여경로</th>
                          <td><p className="pl6">{post.dosageRoute}</p></td>
                        </tr>
                        <tr>
                          <th>허가일</th>
                          <td><p className="pl6">{post.permitDate}</p></td>
                          <th>대조 &#47; 생동</th>
                          <td><p className="pl6">{post.bioeqCompDrug}</p></td>
                        </tr>
                        <tr>
                          <th>포장단위</th>
                          <td><p className="pl6">{post.packageImg}</p></td>
                          <th>durContra</th>
                          <td><p className="pl6">{post.durContra}</p></td>
                        </tr>
                        <tr>
                          <th>용량주의</th>
                          <td><p className="pl6">{post.durDose}</p></td>
                          <th>분할주의</th>
                          <td><p className="pl6">{post.durForm}</p></td>
                        </tr>
                        <tr>
                          <th>투여기간주의</th>
                          <td><p className="pl6">{post.durPeriod}</p></td>
                          <th>복약정보 픽토그램</th>
                          <td><p className="pl6">{post.pictoUrl}</p></td>
                        </tr>
                      </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drug;