import FileSaver from 'file-saver';
import * as XLSX from 'xlsx'

const ExportCSV = ({ csvData, fileName, fileHeading, download }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const txtConvert = (data) => {
    let i = 0;
    let character = '';
    let rtndata = '';
    while (i < data.length) {
      character = data.charAt(i);
      if (!isNaN(character)) {
        rtndata += character;
      } else {
        if (character === character.toUpperCase()) {
          rtndata += '_' + character.toLowerCase();
        }
        if (character === character.toLowerCase()) {
          rtndata += character;
        }
      }
      i++;
    }
    return rtndata;
  }

  const copyHead = () => {
    let copyHeading = {};
    for (let key in fileHeading) {
      copyHeading[key] = fileHeading[key];
      copyHeading[key] = txtConvert(key);
    }
  
    return [copyHeading];
  }

  const exportToCSV = (csvData, fileName) => {
    download().then((exportData) => {
      csvData = exportData;
      const ws = XLSX.utils.json_to_sheet(copyHead(), {
        // header: [],
        header: Object.keys(copyHead()[0]),
        skipHeader: true,
        origin: 0
      });
      XLSX.utils.sheet_add_json(ws, csvData, {
        // header: [],
        header: Object.keys(copyHead()[0]),
        skipHeader: true,
        origin: -1
      });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }).catch(function () {
      console.error('파일 다운로드에 실패했습니다.');
    });
  };
  return (
    <button onClick={e => exportToCSV(csvData, fileName)} className="btnexcel">엑셀 다운로드</button>
  );
};

export default ExportCSV;