import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';

function Order() {
  const navigate = useNavigate();

  const BASE_URL = `http://pay.charmacist.net/_api/api_order_list.php`;
  const BLOCK_SIZE = 10;

  const _OdrStatusType = {
    Cart: 100,
    Del: 120,
    New: 200,
    Cancel: 220,
    Prepare: 250,
    Vbank: 290,
    Paid: 300,
    Refund: 320,
  };

  const _OdrStatusName = {
    Cart: '주문 생성',
    Del: '주문 삭제',
    New: '주문 발주',
    Cancel: '주문 취소',
    Prepare: '주문 승인',
    Vbank: '입금 대기',
    Paid: '결제 완료',
    Refund: '반품 환불',
  };

  const [no, setNo] = useState(1);
  const [total, setTotal] = useState(1);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    getPostList();
  }, [no]);

  const getPostList = () => {
    fetch(`${BASE_URL}?page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined) {
          setTotal(parseInt(data.total, 10));
          setPostList(data.data);
        }
      });
  };

  const displayStatus = (pData) => {
    let data = parseInt(pData, 10);
    if (Object.values(_OdrStatusType).includes(data) === true) {
      let type = getAttrKey(_OdrStatusType, data);
      for (let key in _OdrStatusName) {
        if (type === key) { return _OdrStatusName[key]; }
      }
    }
  };

  const getAttrKey = (obj, val) => {
    let keys = Object.keys(obj);
    let rtnKey = keys.find(function (key) {
      return obj[key] === val;
    });
    return rtnKey;
  };

  const goDetail = (idx, orderNo, custCode) => {
    if (idx !== undefined && orderNo !== undefined && custCode !== undefined) {
      navigate(`/charmacist/OrderDetail?idx=${idx}&orderNo=${orderNo}&cust=${custCode}`);
    } else {
      navigate(`/charmacist/OrderDetail`);
    }
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="charmacist" title="초도주문" />
            <div className="subst">
              <div className="d_table">
                <table className="dtable" summary="초도주문 목록 입니다.">
                  <caption>초도주문 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="100" />
                    <col width="100" />
                    <col width="*" />
                    <col width="80" />
                    <col width="80" />
                    <col width="100" />
                    <col width="260" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">주문일시</th>
                      <th scope="col">주문상태</th>
                      <th scope="col">제목</th>
                      <th scope="col">항목수</th>
                      <th scope="col">전체수량</th>
                      <th scope="col">주문금액</th>
                      <th scope="col">주문일시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      postList.map(post => (
                        <tr key={post.odr_orderNo} className="ov" onClick={() => goDetail(post.odr_idx, post.odr_orderNo, post.odr_custCode)}>
                          <td>{post.odr_idx}</td>
                          <td>{post.odr_date}</td>
                          <td>{displayStatus(post.odr_status)}</td>
                          <td>{post.odr_title}</td>
                          <td>{post.odr_count}</td>
                          <td>{post.odr_qty}</td>
                          <td>{post.odr_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                          <td>{post.odr_modDate}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={total} pageSize={BLOCK_SIZE}></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;