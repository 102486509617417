import { useContext, useRef, useState, useEffect, Fragment } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import Pagination from '../pagination/Pagination.js';
import ExportCSV from '../excel/ExportCSV';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';

function Algorithm() {
  const isMounted = useRef(false);

  const BASE_URL = useContext(UrlContext);
  const PAGE_URL = BASE_URL + `conditions`;
  const BLOCK_SIZE = 10;

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);

  const [solution, setSolution] = useState('');
  const [drugForm, setDrugForm] = useState('');
  const [cancelDate, setCancelDate] = useState(new Date());
  const [cancelDateBetween, setCancelDateBetween] = useState(new Date());
  const [cancelDateCondition, setCancelDateCondition] = useState('');
  const [nullValue, setNullValue] = useState('0');
  const [drugClass, setDrugClass] = useState('');
  const [drugName, setDrugName] = useState([]);
  const [drugSunbEtc, setDrugSunbEtc] = useState([]);
  const [volume, setVolume] = useState('');
  const [volumeBetween, setVolumeBetween] = useState('');
  const [unit, setUnit] = useState('');
  const [sunbCondition, setSunbCondition] = useState('');
  const [kpicAtc, setKpicAtc] = useState('');
  const [clsCode, setClsCode] = useState('');
  const [effect, setEffect] = useState([]);
  const [durAge, setDurAge] = useState('');
  const [durPreg, setDurPreg] = useState('');
  const [durSenior, setDurSenior] = useState('');
  const [itemIngrType, setItemIngrType] = useState('');
  const [atcCode, setAtcCode] = useState([]);

  const [date, setDate] = useState({});
  const [isBetween, setIsBetween] = useState(false);
  const [isMultipleSunb, setIsMultipleSunb] = useState(false);
  const [isMoreSunb, setIsMoreSunb] = useState(false);

  const [drugFormKind, setDrugFormKind] = useState([]);
  const [drugSunbCondition, setDrugSunbCondition] = useState([]);
  const [postList, setPostList] = useState([]);
  const [post, setPost] = useState({});
  const [isLayer, setIsLayer] = useState(false);

  const exportData = useRef([]);
  const [exportParam, setExportParam] = useState({
    solution: '', atcCode: '', bioeqCompDrug: '', bohCode: '', cancelDate: ''
    , charact: '', clsCode: '', dosage: '', dosageRoute: ''
    , drugAmount: '', drugClass: '', drugCode: '', drugEname: '', drugForm: ''
    , drugName: '', drugPackage: '', drugSize: '', drugSunbEtc: ''
    , durAge: '', durContra: '', durDose: '', durForm: '', durPeriod: ''
    , durPreg: '', durSenior: '', ediCode: '', effect: '', flag: '', form: ''
    , genMedi: '', hiraSunbCode: '', idfyImg: '', indicMedi: '', itemIngrType: ''
    , itemSeq: '', kfdaItemSeq: '', kpicAtc: '', listBoh: '', packImg: '', packageImg: ''
    , permitDate: '', pictoUrl: '', remark: '', rpCode: '', sdCode: '', stmt: '', upsoName: ''
  });

  useEffect(() => {
    init();
    getDrugFormKind();
  }, []);
  
  useEffect(() => {
    if (isMounted.current) {
      getPostList();
    } else {
      isMounted.current = true;
    }
  }, [no]);

  useEffect(() => {
    if (cancelDate !== null) {
      let yyyy = cancelDate.getFullYear();
      let mm = String(cancelDate.getMonth() + 1).padStart(2, '0');
      let dd = String(cancelDate.getDate()).padStart(2, '0');
      setDate({ ...date, ['cancelDate']: yyyy + '-' + mm + '-' + dd });
    }
  }, [cancelDate]);

  useEffect(() => {
    if (cancelDateBetween !== null) {
      let yyyy = cancelDateBetween.getFullYear();
      let mm = String(cancelDateBetween.getMonth() + 1).padStart(2, '0');
      let dd = String(cancelDateBetween.getDate()).padStart(2, '0');
      setDate({ ...date, ['cancelDateBetween']: yyyy + '-' + mm + '-' + dd });
    }
  }, [cancelDateBetween]);

  useEffect(() => {
    if (cancelDateCondition === '사이') {
      setIsBetween(true);
    } else if (cancelDateCondition === '') {
      setIsBetween(false);
      setCancelDateBetween(null);
      setDate({ ...date, ['cancelDate']: null, ['cancelDateBetween']: null });
    } else {
      setIsBetween(false);
      setCancelDateBetween(null);
      setDate({ ...date, ['cancelDateBetween']: null });
    }
  }, [cancelDateCondition]);

  const init = () => {
    setDrugClass('일반');
    setCancelDate(null);
    setCancelDateBetween(null);
    setSunbCondition('이상');
    setDrugName(['']);
    setDrugSunbEtc(['']);
    setEffect(['']);
    setAtcCode(['']);
  };

  const getDrugFormKind = () => {
    fetch(`${PAGE_URL}/drug/form`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setDrugFormKind(data.data);
        }
      });
  };

  const isMultipleFlag = (e) => {
    const { name, value } = e.target;
    setIsMultipleSunb(parseInt(value, 10));

    resetSunbValue();
    setDrugSunbEtc(['']);
  };

  const addSunbValue = () => {
    if (drugSunbEtc.length > 0) {
      setIsMoreSunb(true);

      fetch(`${BASE_URL}sunb?name=${drugSunbEtc}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data !== undefined && data.code !== undefined && data.code === '200') {
            console.log(data.data);
            setDrugSunbCondition(data.data);
          }
        });
    } else { alert('성분을 입력하세요.'); }
  };

  const resetSunbValue = () => {
    setIsMoreSunb(false);
    setUnit('');
    setVolume('');
    setVolumeBetween('');
    setSunbCondition('이상');
  };

  const addDrugName = (e, idx) => {
    const { value } = e.target;
    const inputs = drugName;
    inputs[idx] = value;
    setDrugName(inputs);
  };

  const addDrugSunbEtc = (e, idx) => {
    const { value } = e.target;
    const inputs = drugSunbEtc;
    inputs[idx] = value;
    setDrugSunbEtc(inputs);
  };

  const addEffect = (e, idx) => {
    const { value } = e.target;
    const inputs = effect;
    inputs[idx] = value;
    setEffect(inputs);
  };

  const addAtcCode = (e, idx) => {
    const { value } = e.target;
    const inputs = atcCode;
    inputs[idx] = value;
    setAtcCode(inputs);
  };

  const addMultipleName = () => {
    setDrugName([...drugName, '']);
  };

  const addMultipleSunb = () => {
    setDrugSunbEtc([...drugSunbEtc, '']);
  };

  const addMultipleEffect = () => {
    setEffect([...effect, '']);
  };

  const addInput = () => {
    setAtcCode([...atcCode, '']);
  };

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') {
      if (no !== 1) {
        setNo(1);
      } else {
        getPostList();
      }
    }
  };

  const getPostList = () => {
    if (isMoreSunb === true) {
      if (sunbCondition === '범위' && (volume.length === 0 || volumeBetween.length === 0)) {
        alert('함량의 범위를 지정해 주세요.');
        return false;
      } else if (volume.length === 0 || unit.length === 0) {
        alert('함량과 단위를 입력해 주세요.');
        return false;
      }
    }
    if (cancelDate !== null && cancelDateCondition.length === 0) {
      alert('취하일자의 검색조건을 입력해 주세요.'); return false;
    }
    if (cancelDate === null && nullValue === '1') {
      alert('취하일자를 선택해 주세요.'); return false;
    }

    let param = {
      solution: solution, drugForm: drugForm, cancelDate: cancelDate, cancelDateBetween: cancelDateBetween, nullValue: nullValue
      , cancelDateCondition: cancelDateCondition, drugClass: drugClass, drugName: JSON.stringify(drugName), drugSunbEtc: JSON.stringify(drugSunbEtc)
      , volume: volume, volumeBetween: volumeBetween, unit: unit, sunbCondition: sunbCondition, kpicAtc: kpicAtc
      , clsCode: clsCode, effect: JSON.stringify(effect), atcCode: JSON.stringify(atcCode)
      , durAge: durAge, durPreg: durPreg, durSenior: durSenior, itemIngrType: itemIngrType
    };

    console.log(param);
    let mergeParam = Object.assign(param, date);
    let queryParams = Object.keys(mergeParam).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(mergeParam[k])).join('&');
    console.log(queryParams);

    fetch(`${PAGE_URL}/search?${queryParams}&page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  };

  const detail = (sdCode) => {
    fetch(`${PAGE_URL}/search/detail?sdCode=${sdCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setIsLayer(true);
        setPost(data.data);
      });
  };

  const download = () => {
    return new Promise(function (resolve, reject) {
      if (postList.length > 0) {
        let param = {
          solution: solution, drugForm: drugForm, cancelDate: cancelDate, cancelDateBetween: cancelDateBetween, nullValue: nullValue
          , cancelDateCondition: cancelDateCondition, drugClass: drugClass, drugName: JSON.stringify(drugName), drugSunbEtc: JSON.stringify(drugSunbEtc)
          , volume: volume, volumeBetween: volumeBetween, unit: unit, sunbCondition: sunbCondition, kpicAtc: kpicAtc
          , clsCode: clsCode, effect: JSON.stringify(effect), atcCode: JSON.stringify(atcCode)
          , durAge: durAge, durPreg: durPreg, durSenior: durSenior, itemIngrType: itemIngrType
        };
  
        console.log(param);
        let mergeParam = Object.assign(param, date);
  
        fetch(`${PAGE_URL}/excel`, {
          method: 'POST',
          body: JSON.stringify(mergeParam),
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data) => {
            console.log(data);
            if (data !== undefined && data.code !== undefined && data.code === '200') {
              console.log(data.data);
              exportData.current = data.data;
              console.log(exportData.current);
              resolve(data.data);
            } else {
              alert('엑셀을 다운로드 받지 못했습니다.');
              reject();
            }
          });
      } else {
        alert('다운로드 할 데이터를 먼저 검색해주세요.');
      }
    });
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="yakgorithm" title="의약품 조건검색"/>
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>솔루션</th>
                      <td>
                        <input onChange={e => setSolution(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>제형</th>
                      <td>
                        <select onChange={e => setDrugForm(e.target.value)} className="valm ml10 mr35" style={{width:'126px'}}>
                          <option value="">선택하세요.</option>
                          {
                            (drugFormKind !== '' && drugFormKind.length > 0) && (
                              drugFormKind.map((data, index) => (
                                <option key={data} value={data}>{data}</option>
                              ))
                            )
                          }
                        </select>
                      </td>
                      <th>종류</th>
                      <td>
                        <label className="ml10 mr10">
                          <input onChange={e => setDrugClass(e.target.value)} type="radio" name="drugClass" value="일반" defaultChecked /><span className="ml5">일반</span>
                        </label>
                        <label>
                          <input onChange={e => setDrugClass(e.target.value)} type="radio" name="drugClass" value="전문" /><span className="ml5">전문</span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th>제품명</th>
                      <td colSpan="5">
                        {
                          drugName.map((input, index) => (
                          <p key={index} className="schmorelist">
                            <input onChange={e => addDrugName(e, index)} onKeyPress={(e) => search(e)} type="text" className="inp valm" style={{ 'width': '168px' }} />
                            <button onClick={addMultipleName} type="button" className="schmorebtn on" title="제품명 추가"></button>
                          </p>
                          ))
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>취하일자</th>
                      <td colSpan="5">
                      <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={cancelDate} onChange={e => setCancelDate(e)} customInput={
                          <input className="inp valm mr5" type="text" style={{ width: '168px' }} />
                        } />
                        {
                          isBetween
                          &&
                          <DatePicker locale={ko} dateFormat="yyyy-MM-dd" selected={cancelDateBetween} onChange={e => setCancelDateBetween(e)} customInput={
                            <input className="inp valm mr10" type="text" style={{ width: '168px' }} />
                          } />
                        }
                        <label className="mr10">
                          <input onChange={e => setCancelDateCondition(e.target.value)} type="radio" name="cancelDateCondition" value="이전"/>
                          <span className="ml5">이전</span>
                        </label>
                        <label className="mr10">
                          <input onChange={e => setCancelDateCondition(e.target.value)} type="radio" name="cancelDateCondition" value="이후" />
                          <span className="ml5">이후</span>
                        </label>
                        <label className="mr10">
                          <input onChange={e => setCancelDateCondition(e.target.value)} type="radio" name="cancelDateCondition" value="사이" />
                          <span className="ml5">사이</span>
                        </label>
                        <label className="mr10">
                          <input onChange={e => setCancelDateCondition(e.target.value)} type="radio" name="cancelDateCondition" value="일치" />
                          <span className="ml5">일치</span>
                        </label>
                        <label className="mr10">
                          <input onChange={e => setCancelDateCondition(e.target.value)} type="radio" name="cancelDateCondition" value="" defaultChecked />
                          <span className="ml5">해당없음</span>
                        </label>
                        <label>
                          <input onChange={e => setNullValue(e.target.value)} type="checkbox" name="nullValue" value={nullValue === '1' ? '0' : '1'} checked={nullValue === '1'} />
                          <span className="ml5">null 포함</span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th>성분</th>
                      <td>
                        <div className="pl6">
                          <label className="mr10">
                            <input onChange={isMultipleFlag} type="radio" name="multiple" value="0" checked={isMultipleSunb == 0} />
                            <span className="ml5">성분 1개 입력</span>
                          </label>
                          <label>
                            <input onChange={isMultipleFlag} type="radio" name="multiple" value="1" checked={isMultipleSunb == 1} />
                            <span className="ml5">성분 여러개 입력</span>
                          </label>
                        </div>
                      </td>
                      <th>성분 검색</th>
                      {
                        isMultipleSunb == 0 ? (
                          <td colSpan="3">
                            <input onChange={e => addDrugSunbEtc(e, 0)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                            <button onClick={() => addSunbValue()} type="button" className="btprimary ml5 valm" style={{ width: '60px', padding: '0' }}>상세입력</button>
                          </td>
                        ) : (
                          <td colSpan="3">
                            {
                              drugSunbEtc.map((input, index) => (
                              <p key={index} className="schmorelist">
                                <input onChange={e => addDrugSunbEtc(e, index)} onKeyPress={(e) => search(e)} type="text" className="inp valm" style={{ 'width': '168px' }} />
                                <button onClick={addMultipleSunb} type="button" className="schmorebtn on" title="성분 추가"></button>
                              </p>
                              ))
                            }
                          </td>
                        )
                      }
                    </tr>
                      {
                        isMultipleSunb == 0 && isMoreSunb === true && (
                          <tr>
                            <th>함량&#47;단위</th>
                            <td colSpan="5">
                              <input onChange={e => setVolume(e.target.value)} onKeyPress={(e) => search(e)} className="inp mr5 valm" type="text" style={{ width: '81px' }} />
                              {
                                sunbCondition === '범위' && (
                                  <input onChange={e => setVolumeBetween(e.target.value)} className="inp mr5 valm" type="text" style={{ width: '81px' }} />
                                )
                              }
                              <select onChange={e => setUnit(e.target.value)} className="valm mr35">
                                <option value="">선택하세요.</option>
                                {
                                  (drugSunbCondition !== '' && drugSunbCondition.length > 0) && (
                                    drugSunbCondition.map((data, index) => (
                                      <option key={data} value={data}>{data}</option>
                                    ))
                                  )
                                }
                              </select>
                              <label className="mr10">
                                <input onChange={e => setSunbCondition(e.target.value)} type="radio" name="sunbCondition" value="이상" defaultChecked /><span className="ml5">이상</span>
                              </label>
                              <label className="mr10">
                                <input onChange={e => setSunbCondition(e.target.value)} type="radio" name="sunbCondition" value="이하" /><span className="ml5">이하</span>
                              </label>
                              <label className="mr10">
                                <input onChange={e => setSunbCondition(e.target.value)} type="radio" name="sunbCondition" value="일치" /><span className="ml5">일치</span>
                              </label>
                              <label>
                                <input onChange={e => setSunbCondition(e.target.value)} type="radio" name="sunbCondition" value="범위" /><span className="ml5">범위</span>
                              </label>
                              <button onClick={() => resetSunbValue() } type="button" className="btgray ml10 valm" style={{ width: '60px', padding: '0' }}>숨기기</button>
                            </td>
                          </tr>
                        )
                      }
                    <tr>
                      <th>KPIC 약효분류</th>
                      <td>
                        <input onChange={e => setKpicAtc(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>보건복지부 분류번호</th>
                      <td>
                        <input onChange={e => setClsCode(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>단일&#47;복합</th>
                      <td>
                        <label className="ml10 mr10">
                          <input onChange={e => setItemIngrType(e.target.value)} type="radio" name="itemIngrType" value="단일" /><span className="ml5">단일</span>
                        </label>
                        <label className="mr10">
                          <input onChange={e => setItemIngrType(e.target.value)} type="radio" name="itemIngrType" value="복합" /><span className="ml5">복합</span>
                        </label>
                        <label>
                          <input onChange={e => setItemIngrType(e.target.value)} type="radio" name="itemIngrType" value="" defaultChecked /><span className="ml5">해당없음</span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th>연령금기</th>
                      <td>
                        <input onChange={e => setDurAge(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>임부금기</th>
                      <td>
                        <input onChange={e => setDurPreg(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                      <th>노인금기</th>
                      <td>
                        <input onChange={e => setDurSenior(e.target.value)} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ width: '168px' }} />
                      </td>
                    </tr>
                    <tr>
                      <th>효능&#47;효과</th>
                      <td colSpan="5">
                        {
                          effect.map((input, index) => (
                          <p key={index} className="schmorelist">
                            <input onChange={e => addEffect(e, index)} onKeyPress={(e) => search(e)} type="text" className="inp valm" style={{ 'width': '168px' }} />
                            <button onClick={addMultipleEffect} type="button" className="schmorebtn on" title="효능/효과 추가"></button>
                          </p>
                          ))
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>ATC 코드</th>
                      <td colSpan="4">
                        {
                          atcCode.map((input, index) => (
                          <p key={index} className="schmorelist">
                            <input onChange={e => addAtcCode(e, index)} onKeyPress={(e) => search(e)} type="text" className="inp valm" style={{ 'width': '168px' }} />
                            <button onClick={addInput} className="schmorebtn on" type="button" title="ATC 코드 추가"></button>
                          </p>
                          ))
                        }
                      </td>
                      <td>
                        <p className="btnlist">
                          <button onClick={(e) => search(e)} className="btnsch" type="button">검색</button>
                          <ExportCSV csvData={exportData.current} fileName="의약품_조건검색" fileHeading={exportParam} download={download} />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d_table mt30">
                <table className="dtable" summary="의약품 조건검색 목록 입니다.">
                  <caption>의약품 조건검색 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="80" />
                    <col width="260" />
                    <col width="100" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">전문/일반</th>
                      <th scope="col">제형</th>
                      <th scope="col">제품명</th>
                      <th scope="col">제조/수입사</th>
                      <th scope="col">포장단위</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      postList.length === 0 && (
                        <tr>
                          <td colSpan="5">검색 내역이 없습니다.</td>
                        </tr>
                      )
                    }
                    {
                      postList.length > 0 && (
                        postList.map(post => (
                          <tr key={post.sdCode} onClick={() => { detail(post.sdCode) }} className="ov">
                            <td>{post.drugClass}</td>
                            <td>{post.drugForm}</td>
                            <td>{post.drugName}</td>
                            <td>{post.upsoName}</td>
                            <td>{post.packageImg}</td>
                          </tr>
                        ))
                      )
                    }
                  </tbody>
                </table>
              </div>
              <Pagination pageNo={no} setNo={setNo} pageTotal={page.total} pageSize={BLOCK_SIZE}></Pagination>
              {
                isLayer === true ? (
                <div className="layerpop">
                  <div className="layttl">
                    <p>의약품 상세 정보</p>
                    <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                  </div>
                    <div className="layerpopin">
                      <div className="dv_table w100">
                        <form>
                          <table className="dvtable" summary="의약품 상세 정보 입니다.">
                          <caption>의약품 상세 정보</caption>
                          <colgroup>
                            <col width="135" />
                            <col width="*" />
                            <col width="135" />
                            <col width="*" />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>표준코드</th>
                                <td colSpan="3"><p className="pl6">{post.sdCode}</p></td>
                              </tr>
                              <tr>
                                <th>전문 &#47; 일반</th>
                                <td><p className="pl6">{post.drugClass}</p></td>
                                <th>단일 &#47; 복합</th>
                                <td><p className="pl6">{post.itemIngrType}</p></td>
                              </tr>
                              <tr>
                                <th>제형</th>
                                <td><p className="pl6">{post.drugForm}</p></td>
                                <th>취하일자</th>
                                <td><p className="pl6">{post.cancelDate}</p></td>
                              </tr>
                              <tr>
                                <th>제품명</th>
                                <td colSpan="3"><p className="pl6">{post.drugName}</p></td>
                              </tr>
                              <tr>
                                <th>성분 &#47; 함량</th>
                                <td colSpan="3"><p className="pl6">{post.drugSunbEtc}</p></td>
                              </tr>
                              <tr>
                                <th>효능 &#47; 효과</th>
                                <td colSpan="3"><p className="pl6">{post.effect}</p></td>
                              </tr>
                              <tr>
                                <th>ATC 코드</th>
                                <td colSpan="3"><p className="pl6">{post.atcCode}</p></td>
                              </tr>
                              <tr>
                                <th>KPIC 약효분류</th>
                                <td colSpan="3"><p className="pl6">{post.kpicAtc}</p></td>
                              </tr>
                              <tr>
                                <th>보건복지부 분류번호</th>
                                <td colSpan="3"><p className="pl6">{post.clsCode}</p></td>
                              </tr>
                              <tr>
                                <th>연령금기</th>
                                <td colSpan="3"><p className="pl6">{post.durAge}</p></td>
                              </tr>
                              <tr>
                                <th>임부금기</th>
                                <td colSpan="3"><p className="pl6">{post.durPreg}</p></td>
                              </tr>
                              <tr>
                                <th>노인주의</th>
                                <td colSpan="3"><p className="pl6">{post.senior}</p></td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                      <div className="laybtn pt25">
                        <button onClick={closeLayer} className="btblack">닫기</button>
                      </div>
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Algorithm;