import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import UrlContext from './components/common/UrlContext';
import Layout from './components/common/Layout';
import Index from './components/common/Index';
import Login from './components/login/Index';
import Consult from './components/charmacist/Consult';
import ConsultDetail from './components/charmacist/ConsultDetail';
import Order from './components/charmacist/Order';
import OrderDetail from './components/charmacist/OrderDetail';
import Balance from './components/charmacist/Balance';
import VIP from './components/charmacist/VIP';
import Pay from './components/charmacist/Pay';
import Disease from './components/pharmstit/Disease';
import Ingredient from './components/pharmstit/Ingredient';
import HiraSunbToBoh from './components/pharmstit/HiraSunbToBoh';
import MedGuide from './components/pharmstit/MedGuide';
import Drug from './components/pharmstit/Drug';
import Condition from './components/yakgorithm/Condition';
import After from './components/yakgorithm/After';
import Algorithm from './components/yakgorithm/Algorithm';
import Category from './components/test/Category';
import Product from './components/test/Product';
import Barcode from './components/test/Barcode';
import DrugToBarcode from './components/test/DrugToBarcode';
import Contact from './components/test/Contact';
import ContactDetail from './components/test/ContactDetail';

function App() {
  return (
    // <UrlContext.Provider value={'http://192.168.3.106:8070/platform/'}>
    <UrlContext.Provider value={'http://svr.charmacist.net/platform/'}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/charmacist" />} />

          <Route path="/login" element={<Navigate replace to="/login/Index" />} />
          <Route path="/login" element={<Login />}>
            <Route path="Index" element={<Login />} />
          </Route>

          <Route path="/charmacist" element={<Navigate replace to="/charmacist/Index" />} />
          <Route path="/charmacist" element={<Layout project="charmacist" />}>
            <Route path="Index" element={<Index project="charmacist" />} />
            <Route path="Consult" element={<Consult />} />
            <Route path="ConsultDetail/*" element={<ConsultDetail />} />
            <Route path="Order" element={<Order />} />
            <Route path="OrderDetail/*" element={<OrderDetail />} />
            <Route path="Balance" element={<Balance />} />
            <Route path="VIP" element={<VIP />} />
            <Route path="Pay" element={<Pay />} />
          </Route>

          <Route path="/pharmstit" element={<Navigate replace to="/pharmstit/Index" />} />
          <Route path="/pharmstit" element={<Layout project="pharmstit" />}>
            <Route path="Index" element={<Index project="pharmstit" />} />
            <Route path="Disease" element={<Disease />} />
            <Route path="Ingredient" element={<Ingredient />} />
            <Route path="HiraSunbToBoh" element={<HiraSunbToBoh />} />
            <Route path="MedGuide" element={<MedGuide />} />
            <Route path="Drug" element={<Drug />} />
          </Route>

          <Route path="/yakgorithm" element={<Navigate replace to="/yakgorithm/Index" />} />
          <Route path="/yakgorithm" element={<Layout project="yakgorithm" />}>
            <Route path="Index" element={<Index project="yakgorithm" />} />
            <Route path="Condition" element={<Condition />} />
            <Route path="After" element={<After />} />
            <Route path="Algorithm" element={<Algorithm />} />
          </Route>

          <Route path="/test" element={<Navigate replace to="/test/Index" />} />
          <Route path="/test" element={<Layout project="test" />}>
            <Route path="Index" element={<Index project="test" />} />
            <Route path="Category" element={<Category />} />
            <Route path="Product" element={<Product />} />
            <Route path="Barcode" element={<Barcode />} />
            <Route path="DrugToBarcode" element={<DrugToBarcode />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="ContactDetail/*" element={<ContactDetail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UrlContext.Provider>
  );
}

export default App;