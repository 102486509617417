import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import HeadTitle from '../common/headTitle';

function OrderDetail() {
  const location = useLocation();

  const QUERY_STRING = location.search;
  const BASE_URL = `http://pay.charmacist.net/_api/api_order_detail.php`;

  const [order, setOrder] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [displayOrderList, setDisplayOrderList] = useState([]);

  useEffect(() => {
    getPost();
  }, []);
  
  useEffect(() => {
    if (orderList.length > 0) {
      setDisplayOrderList(orderList.map((obj, idx) => {
        if (obj.ca_full_name !== null && obj.ca_full_name.length > 0) {
          let arrCate = JSON.parse(obj.ca_full_name);
          let strCate = '';
    
          for (let key in arrCate) {
            if (arrCate[key] !== null && arrCate[key].length > 0) {
              if (strCate.length > 0) { strCate += ' > '; }
              strCate += arrCate[key];
            }
            orderList[idx].ca_full_display_name = strCate;
          }
        }
        return obj;
      }));
    }
  }, [orderList]);

  const getPost = () => {
    fetch(`${BASE_URL}${QUERY_STRING}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined) {
          console.log(data);
          setOrder(data.data);
          setOrderList(data.list);
        }
      });
  };

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="charmacist" title="초도주문" />
              {
                order !== undefined &&
                (
                  <div className="subst">
                      <h3 className="stit">[ 회원정보 ]</h3>
                      <div className="dv_table">
                        <table className="dvtable" summary="주문회원 상세 정보 입니다.">
                          <caption>주문회원</caption>
                          <colgroup>
                            <col width="120" />
                            <col width="*" />
                            <col width="120" />
                            <col width="*" />
                            <col width="120" />
                            <col width="*" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>약국정보</th>
                              <td><p className="pl6">{order.phm_custName} / {order.phm_bizRegNo}</p></td>
                              <th>약사</th>
                              <td><p className="pl6">{order.phm_userName}</p></td>
                              <th>연락처</th>
                              <td><p className="pl6">{order.phm_contact}</p></td>
                            </tr>
                            <tr>
                              <th>우편번호</th>
                              <td>
                                <p className="pl6">{order.phm_postCode}</p>
                              </td>
                              <th>주소</th>
                              <td colSpan="3">
                                <p className="pl6">{order.phm_addr1} {order.phm_addr2}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h3 className="stit pt20">[ 주문정보 ]</h3>
                      <div className="dv_table">
                        <table className="dvtable" summary="주문 상세 정보 입니다.">
                          <caption>주문</caption>
                          <colgroup>
                            <col width="120" />
                            <col width="*" />
                            <col width="120" />
                            <col width="*" />
                            <col width="120" />
                            <col width="*" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주문상태</th>
                              <td>
                                <p className="pl6"><strong>주문 생성</strong></p>
                              </td>
                              <th>주문번호</th>
                              <td colSpan="3">
                                <p className="pl6">{order.odr_orderNo}</p>
                              </td>
                            </tr>
                            <tr>
                              <th>결제수단</th>
                              <td>
                                <p className="pl6">{order.odr_pgCorp}</p>
                              </td>
                              <th>결제정보</th>
                              <td colSpan="3">
                                <p className="pl6">
                                  {
                                    (order.odr_payDetail !== undefined && order.odr_payDetail.length > 10) &&
                                      (
                                        (JSON.parse(order.odr_payDetail)).cardCompanyName + ' ' + (JSON.parse(order.odr_payDetail)).cardNo
                                      )
                                  }
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h3 className="stit pt20">[ 주문내역 ]</h3>
                      <div className="d_table">
                        <table className="dtable" summary="주문내역 상세 정보 입니다.">
                          <caption>주문내역</caption>
                            <colgroup>
                              <col width="120" />
                              <col width="*" />
                              <col width="100" />
                              <col width="150" />
                              <col width="150" />
                            </colgroup>
                          <thead>
                            <tr>
                              <th>상품코드</th>
                              <th>상품명</th>
                              <th>단가</th>
                              <th>수량</th>
                              <th>금액</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              displayOrderList.map((prod, index) => (
                                <tr key={prod.pd_code}>
                                  <td>{prod.pd_code}</td>
                                  <td>
                                    <p className="pl6">
                                      <img src={prod.pd_tiny_image} className="valm" style={{ width: '50px', border: 0 }} />
                                      [{prod.ca_full_display_name}] {prod.odd_name}
                                    </p>
                                  </td>
                                  <td>{prod.odd_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                  <td>{prod.odd_qty}</td>
                                  <td>{prod.odd_totalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th><strong>합계</strong></th>
                              <th><strong>{order.odr_qty}</strong></th>
                              <th><strong>{order.odr_price !== undefined && order.odr_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></th>
                            </tr>
                            <tr>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th><strong>VAT</strong></th>
                              <th>&nbsp;</th>
                              <th>
                                <strong>
                                  {
                                    order.odr_price !== undefined &&
                                    (
                                      (parseInt(order.odr_price, 10) - parseInt(order.odr_priceExcludingTax, 10)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    )
                                  }
                                </strong>
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="bbtn">
                        <p className="bfr">
                          <Link to="/charmacist/Order" className="bt btgray">목록으로</Link>
                        </p>
                      </div>
                  </div>
                )
              }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;