function Footer() {
  return (
    <div className="footer">
      <div className="footerin">
        <p>Copyright charmacist. Allright Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;