import { useContext, useState } from 'react';
import close from '../../asset/images/btn/icon_x.png';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';

function MedGuide() {
  const BASE_URL = useContext(UrlContext) + `disease`;

  //- 질병코드, 의약품코드 검색
  const [diseasecodeList, setDiseasecodeList] = useState(['']);
  const [bohList, setBohList] = useState(['']);

  //- 질병 필터링 전체, 의약품 필터링 전체
  const [diseaseSpecific, setDiseaseSpecific] = useState([]);
  const [drugSpecific, setDrugSpecific] = useState([]);

  //- 선택한 질병 필터링, 의약품 필터링
  const [diseaseFltr, setDiseaseFltr] = useState([]);
  const [drugFltr, setDrugFltr] = useState([]);

  //- 필터링 된 질병 리스트, 의약품 리스트 (테이블)
  const [diseaseFltrList, setDiseaseFltrList] = useState([]);
  const [drugFltrList, setDrugFltrList] = useState([]);

  //- 질병 리스트, 의약품 리스트 (테이블)
  const [diseaseList, setDiseaseList] = useState([]);
  const [drugList, setDrugList] = useState([]);

  //- 체크한 질병 리스트 code, 의약품 리스트 codeB (key)
  const [diseaseCheckedList, setDiseaseCheckedList] = useState([]);
  const [drugCheckedList, setDrugCheckedList] = useState([]);

  //- 최종 확인 란의 질병 리스트, 의약품 리스트
  const [diseaseGuideList, setDiseaseGuideList] = useState([]);
  const [drugGuideList, setDrugGuideList] = useState([]);

  const [isLayer, setIsLayer] = useState(false);
  const [layerNo, setLayerNo] = useState(0);

  const writeInput = (e, idx, category) => {
    const { value } = e.target;
    if (category === 'disease') {
      let inputs = diseasecodeList;
      inputs[idx] = value;
      setDiseasecodeList(inputs);
    } else if (category === 'drug') {
      let inputs = bohList;
      inputs[idx] = value;
      setBohList(inputs);
    }
  }

  const addInput = (category) => {
    if (category === 'disease') {
      setDiseasecodeList([...diseasecodeList, '']);
    } else if (category === 'drug') {
      setBohList([...bohList, '']);
    }
  }

  const search = (e) => {
    if ((e.charCode === 13 && e.code === 'Enter') || e.type === 'click') { 
      getPostList();
    }
  }

  const getPostList = () => {
    let param = { diseaseCodeList: JSON.stringify(diseasecodeList), bohList: JSON.stringify(bohList) };
    let queryParams = Object.keys(param).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(param[k])).join('&');
    console.log(param);
    console.log(queryParams);

    fetch(`${BASE_URL}/guide?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setDiseaseSpecific(data.data.diseaseSpecific);
          setDrugSpecific(data.data.drugSpecific);
          sortDiseaseList(data.data.result.diseaseResult);
          sortDrugList(data.data.result.drugResult);
        }
      });
  }

  const sortDiseaseList = (data) => {
    // let origin = data.slice(0).sort((a, b) => a.idx - b.idx);
    let origin = data.map((obj) => (
      { ...obj, diseaseSpecificList: obj.diseaseSpecific.replace(/, /gi, ',').split(',') }
    ));
    console.log(origin);
    setDiseaseList(origin);
  }

  const diseaseFiltering = (keyword) => {
    let ahead = new Array();
    let other = new Array();
    
    diseaseList.filter((post, index) => {
      post.diseaseSpecific.indexOf(keyword) > -1 ? ahead.push(post) : other.push(post);
    });

    if (diseaseFltrList.length > 0) {
      diseaseFltrList.forEach(function (obj, idx) {
        if (obj.code !== ahead[0].code) {
          setDiseaseFltrList(diseaseFltrList.concat(ahead));
        }
      });
    } else {
      setDiseaseFltrList(diseaseFltrList.concat(ahead));
    }

    diseaseFltrKeyword(keyword);
  }

  const diseaseFltrKeyword = (keyword) => {
    let fltrObj = new Array();
    let otherFltrObj = new Array();

    if (diseaseFltr.includes(keyword)) {
      let curFltrList = diseaseFltrList.filter((obj) => (obj.diseaseSpecific.includes(keyword)));

      curFltrList.forEach(function (obj, idx) {
        if (obj.diseaseSpecific.length !== keyword.length) {
          let otherKeyword = obj.diseaseSpecificList.filter(object => (object !== keyword))
          otherKeyword.forEach(function (object, index) {
            if (!diseaseFltr.includes(object)) { fltrObj.push(obj); }
          });
        } else { fltrObj.push(obj); }
      });

      diseaseFltrList.forEach(function (obj, idx) {
        let exists = false;
        fltrObj.forEach(function (object, index) {
          if (obj.idx === object.idx) { exists = true; return false; }
        });
        if (exists === false) { otherFltrObj.push(obj); }
      });

      // sortDiseaseList(diseaseList.concat(fltrObj));
      setDiseaseFltrList(otherFltrObj);
      setDiseaseFltr(() => diseaseFltr.filter(obj => obj !== keyword));

    } else {
      setDiseaseFltr([...diseaseFltr, keyword]);
    }
  }

  const checkedAllDiseaseHandler = (checked) => {
    if (checked) {
      let arrCode = new Array();
      if (diseaseCheckedList.length === 0) {
        if (diseaseFltrList.length > 0) {
          diseaseFltrList.forEach(function (obj, idx) {
            arrCode.push(obj.code);
            addDiseaseGuide(obj);
          });
        }
        diseaseList.forEach(function (obj, idx) {
          arrCode.push(obj.code);
          addDiseaseGuide(obj);
        });
      } else {
        if (diseaseFltrList.length > 0) {
          diseaseFltrList.forEach(function (obj, idx) {
            if (!diseaseCheckedList.includes(obj.code)) {
              arrCode.push(obj.code);
              addDiseaseGuide(obj);
            }
          });
        }
        diseaseList.forEach(function (obj, idx) {
          if (!diseaseCheckedList.includes(obj.code)) {
            arrCode.push(obj.code);
            addDiseaseGuide(obj);
          }
        });
      }
      setDiseaseCheckedList([...diseaseCheckedList].concat(arrCode));
    } else {
      setDiseaseCheckedList([]);
      setDiseaseGuideList([]);
    }
  }

  const checkedSingleDiseaseHandler = (data, checked, code) => {
    if (checked) {
      setDiseaseCheckedList(diseaseCheckedList => [...diseaseCheckedList, code]);
      addDiseaseGuide(data);
    } else {
      setDiseaseCheckedList(diseaseCheckedList.filter((obj) => obj !== code));
      removeDiseaseGuide(data);
    }
  }

  const addDiseaseGuide = (data) => {
    setDiseaseGuideList(diseaseGuideList => [...diseaseGuideList, data]);
  }

  const removeDiseaseGuide = (data) => {
    setDiseaseGuideList(diseaseGuideList.filter((diseaseGuideList) => { return diseaseGuideList !== data; }));
  }

  const sortDrugList = (data) => {
    // let origin = data.slice(0).sort((a, b) => a.idx - b.idx);
    let origin = data.map((obj) => (
      { ...obj, drugSpecificList: obj.drugSpecific.replace(/, /gi, ',').split(',') }
    ));
    console.log(origin);
    setDrugList(origin);
  }

  const drugFiltering = (keyword) => {
    let ahead = new Array();
    let other = new Array();

    drugList.filter((post, index) => {
      post.drugSpecific.indexOf(keyword) > -1 ? ahead.push(post) : other.push(post);
    });

    if (drugFltrList.length > 0) {
      drugFltrList.forEach(function (obj, idx) {
        if (obj.code !== ahead[0].code) {
          setDrugFltrList(drugFltrList.concat(ahead));
        }
      });
    } else {
      setDrugFltrList(drugFltrList.concat(ahead));
    }

    drugFltrKeyword(keyword);
  }

  const drugFltrKeyword = (keyword) => {
    let fltrObj = new Array();
    let otherFltrObj = new Array();

    if (drugFltr.includes(keyword)) {
      let curFltrList = drugFltrList.filter((obj) => (obj.drugSpecific.includes(keyword)));

      curFltrList.forEach(function (obj, idx) {
        if (obj.drugSpecific.length !== keyword.length) {
          let otherKeyword = obj.drugSpecificList.filter(object => (object !== keyword))
          otherKeyword.forEach(function (object, index) {
            if (!drugFltr.includes(object)) { fltrObj.push(obj); }
          });
        } else { fltrObj.push(obj); }
      });

      drugFltrList.forEach(function (obj, idx) {
        let exists = false;
        fltrObj.forEach(function (object, index) {
          if (obj.idx === object.idx) { exists = true; return false; }
        });
        if (exists === false) { otherFltrObj.push(obj); }
      });

      // sortDrugList(drugList.concat(fltrObj));
      setDrugFltrList(otherFltrObj);
      setDrugFltr(() => drugFltr.filter(obj => obj !== keyword));

    } else {
      setDrugFltr([...drugFltr, keyword]);
    }
  }

  const checkedAllDrugHandler = (checked) => {
    if (checked) {
      let arrCode = new Array();
      if (drugCheckedList.length === 0) {
        if (drugFltrList.length > 0) {
          drugFltrList.forEach(function (obj, idx) {
            arrCode.push(obj.codeB);
            addDrugGuide(obj);
          });
        }
        drugList.forEach(function (obj, idx) {
          arrCode.push(obj.codeB);
          addDrugGuide(obj);
        });
      } else {
        if (drugFltrList.length > 0) {
          drugFltrList.forEach(function (obj, idx) {
            if (!drugCheckedList.includes(obj.codeB)) {
              arrCode.push(obj.codeB);
              addDrugGuide(obj);
            }
          });
        }
        drugList.forEach(function (obj, idx) {
          if (!drugCheckedList.includes(obj.codeB)) {
            arrCode.push(obj.codeB);
            addDrugGuide(obj);
          }
        });
      }
      setDrugCheckedList([...drugCheckedList].concat(arrCode));
    } else {
      setDrugCheckedList([]);
      setDrugGuideList([]);
    }
  }

  const checkedSingleDrugHandler = (data, checked, code) => {
    if (checked) {
      setDrugCheckedList(drugCheckedList => [...drugCheckedList, code]);
      addDrugGuide(data);
    } else {
      setDrugCheckedList(drugCheckedList.filter((obj) => obj !== code));
      removeDrugGuide(data);
    }
  }

  const addDrugGuide = (data) => {
    setDrugGuideList(drugGuideList => [...drugGuideList, data]);
  }

  const removeDrugGuide = (data) => {
    setDrugGuideList(drugGuideList.filter((drugGuideList) => { return drugGuideList !== data; }));
  }

  const showLayer = (e, idx) => {
    e.preventDefault();
    if (diseaseGuideList.length > 0 || drugGuideList.length > 0) {
      setLayerNo(idx);
      setIsLayer(true);
    } else {
      alert('복약 안내문을 선택해 주세요.');
    }
  }

  const closeLayer = (e) => {
    e.preventDefault();
    setIsLayer(false);
  }

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="pharmstit" title="복약 안내문 생성"/>
            <div className="subst">
              <div className="da_table">
                <table className="datable">
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>질병코드</th>
                      <td colSpan="2">
                        {
                          diseasecodeList.map((input, index) => (
                            <p key={index} className="schmorelist">
                              <input onChange={(e) => writeInput(e, index, 'disease')} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ 'width': '168px' }} />
                              <button onClick={() => addInput('disease')} className="schmorebtn on" type="button" title="질병 코드 추가"></button>
                            </p>
                          ))
                        }
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>의약품코드</th>
                      <td colSpan="2">
                        {
                          bohList.map((input, index) => (
                            <p key={index} className="schmorelist">
                              <input onChange={(e) => writeInput(e, index, 'drug')} onKeyPress={(e) => search(e)} className="inp valm" type="text" style={{ 'width': '168px' }} />
                              <button onClick={() => addInput('drug')} className="schmorebtn on" type="button" title="의약품 코드 추가"></button>
                            </p>
                          ))
                        }
                      </td>
                      <td>
                        <p className="btnlist">
                          <button onClick={search} type="button" className="btnsch">검색</button>
                        </p>
                      </td>
                    </tr>
                    {
                      ((diseaseList.length > 0 || diseaseFltrList.length > 0) || (drugList.length > 0 || drugFltrList.length > 0)) && (
                        <tr>
                          <th>질병 필터링</th>
                          <td>
                            {
                              diseaseSpecific.map((specific, index) => (
                                <button key={index} onClick={(e) => diseaseFiltering(specific)} className={`fltrtag ${diseaseFltr.includes(`${specific}`) ? 'on' : '' }`} type="button">{specific}</button>
                              ))
                            }
                          </td>
                          <th>의약품 필터링</th>
                          <td>
                            {
                              drugSpecific.map((specific, index) => (
                                <button key={index} onClick={(e) => drugFiltering(specific)} className={`fltrtag ${drugFltr.includes(`${specific}`) ? 'on' : '' }`} type="button">{specific}</button>
                              ))
                            }
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d_double mt30">
                <div className="d_table">
                  <table className="dtable" summary="질병 코드로 인한 복약 안내문 목록 입니다.">
                    <caption>복약 안내문 목록</caption>
                    <colgroup>
                      <col width="80" />
                      <col width="80" />
                      <col width="80" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          <input onChange={(e) => checkedAllDiseaseHandler(e.target.checked)} checked={(diseaseList.length > 0 || diseaseFltrList.length > 0) && diseaseCheckedList.length === diseaseList.length + diseaseFltrList.length ? true : false} type="checkbox" />
                        </th>
                        <th scope="col">질병명</th>
                        <th scope="col">특정대상</th>
                        <th scope="col">안내문</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (diseaseFltrList.length === 0 && diseaseList.length === 0) && (
                          <tr>
                            <td colSpan="4">검색 내역이 없습니다.</td>
                          </tr>
                        )
                      }
                      {/* {
                        diseaseFltrList.length > 0 && (
                          diseaseFltrList.map((post, index) => (
                            <tr key={index} className="ac">
                              <td>
                                <input onChange={(e) => checkedSingleDiseaseHandler(post, e.target.checked, post.code)} checked={diseaseCheckedList.includes(post.code) ? true : false} type="checkbox"/>
                              </td>
                              <td>{post.diseasecodeName}</td>
                              <td>{post.diseaseSpecific}</td>
                              <td>
                                {
                                  (post.diseaseGuide !== null && post.diseaseGuide.length > 0)
                                    &&
                                    (
                                      post.diseaseGuide.split('\n').map((guide, idx) => (
                                        <p key={idx} className="pl6">
                                          {
                                            idx === 0 ? (<strong>({post.diseaseGuideCategory} / {post.diseaseLevel}) {guide}</strong>) : (
                                              <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                            )
                                          }
                                        </p>
                                      ))
                                    )
                                }
                              </td>
                            </tr>
                          ))
                        )
                      } */}
                      {
                        diseaseList.length > 0 && (
                          diseaseList.map((post, index) => (
                            <tr key={index} className={post.diseaseSpecificList.filter(keyword => diseaseFltr.includes(keyword)).length > 0 ? "ac" : "ov"}>
                              <td>
                                <input onChange={(e) => checkedSingleDiseaseHandler(post, e.target.checked, post.code)} checked={diseaseCheckedList.includes(post.code) ? true : false} type="checkbox" />
                              </td>
                              <td>{post.diseasecodeName}</td>
                              <td>{post.diseaseSpecific}</td>
                              <td>
                                {
                                  (post.diseaseGuide !== null && post.diseaseGuide.length > 0)
                                    &&
                                    (
                                      post.diseaseGuide.split('\n').map((guide, idx) => (
                                        <p key={idx} className="pl6">
                                          {
                                            idx === 0 ? (<strong>({post.diseaseGuideCategory} / {post.diseaseLevel}) {guide}</strong>) : (
                                              <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                            )
                                          }
                                        </p>
                                      ))
                                    )
                                }
                              </td>
                            </tr>
                          ))
                        )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="d_table">
                  <table className="dtable" summary="의약품 코드로 인한 복약 안내문 목록 입니다.">
                    <caption>복약 안내문 목록</caption>
                    <colgroup>
                      <col width="80" />
                      <col width="80" />
                      <col width="80" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          <input onChange={(e) => checkedAllDrugHandler(e.target.checked)} checked={(drugList.length > 0 || drugFltrList.length > 0) && drugCheckedList.length === drugList.length + drugFltrList.length ? true : false} type="checkbox" />
                        </th>
                        <th scope="col">의약품명</th>
                        <th scope="col">특정대상</th>
                        <th scope="col">안내문</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (drugFltrList.length === 0 && drugList.length === 0) && (
                          <tr>
                            <td colSpan="4">검색 내역이 없습니다.</td>
                          </tr>
                        )
                      }
                      {/* {
                        drugFltrList.length > 0 && (
                          drugFltrList.map((post, index) => (
                            <tr key={index} className="ac">
                              <td>
                                <input onChange={(e) => checkedSingleDrugHandler(post, e.target.checked, post.codeB)} checked={drugCheckedList.includes(post.codeB) ? true : false} type="checkbox" />
                              </td>
                              <td>{post.drugName}</td>
                              <td>{post.drugSpecific}</td>
                              <td>
                                {
                                  (post.typeBGuide !== null && post.typeBGuide.length > 0)
                                  &&
                                  (
                                    post.typeBGuide.split('\n').map((guide, idx) => (
                                      <div key={idx} className="pl6">
                                        <p><strong>({post.guideCategory} / {post.drugLevel})</strong></p>
                                        <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                      </div>
                                    ))
                                  )
                                }
                              </td>
                            </tr>
                          ))
                        )
                      } */}
                      {
                        drugList.length > 0 && (
                          drugList.map((post, index) => (
                            <tr key={index} className={post.drugSpecificList.filter(keyword => drugFltr.includes(keyword)).length > 0 ? "ac" : "ov"}>
                              <td>
                                <input onChange={(e) => checkedSingleDrugHandler(post, e.target.checked, post.codeB)} checked={drugCheckedList.includes(post.codeB) ? true : false} type="checkbox" />
                              </td>
                              <td>{post.drugName}</td>
                              <td>{post.drugSpecific}</td>
                              <td>
                                {
                                  (post.typeBGuide !== null && post.typeBGuide.length > 0)
                                  &&
                                  (
                                    post.typeBGuide.split('\n').map((guide, idx) => (
                                      <div key={idx} className="pl6">
                                        <p><strong>({post.guideCategory} / {post.drugLevel})</strong></p>
                                        <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                      </div>
                                    ))
                                  )
                                }
                              </td>
                            </tr>
                          ))
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="bbtn">
                <p className="bfr"><button onClick={(e) => showLayer(e, 0)} type="button" className="btprimary">최종 확인</button></p>
              </div>

              {isLayer === true && layerNo === 0 ? (
              <div className="layerpop">
                <div className="layttl">
                  <p>복약 안내문 확인</p>
                  <button onClick={closeLayer} className="layclosebtn" type="button"><img src={close} alt="레이어 닫기"/></button>
                </div>
                <div className="layerpopin">
                  {
                    diseaseGuideList.length > 0 && (
                      <div className="d_table pb15">
                        <h3 className="stit pb10">[ 질병 코드로 인한 복약 안내문 ]</h3>
                        <table className="dtable" summary="질병 코드로 인한 복약 안내문 중 사용자가 선택한 목록 입니다.">
                          <caption>질병 코드로 인한 복약 안내문 중 사용자가 선택한 목록</caption>
                          <colgroup>
                            <col width="100" />
                            <col width="*" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th scope="col">질병명</th>
                              <th scope="col">안내문</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              diseaseGuideList.map((post, index) => (
                                <tr key={index}>
                                  <td>{post.diseasecodeName}</td>
                                  <td>
                                    {
                                      (post.diseaseGuide !== null && post.diseaseGuide.length > 0)
                                        &&
                                        (
                                          post.diseaseGuide.split('\n').map((guide, idx) => (
                                            <p key={idx} className="pl6">
                                              {
                                                idx === 0 ? (<strong>({post.diseaseGuideCategory} / {post.diseaseLevel}) {guide}</strong>) : (
                                                  <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                                )
                                              }
                                            </p>
                                          ))
                                        )
                                    }
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                  {
                    drugGuideList.length > 0 && (
                      <div className="d_table">
                        <h3 className="stit pb10">[ 의약품 코드로 인한 복약 안내문 ]</h3>
                        <table className="dtable" summary="의약품 코드로 인한 복약 안내문 중 사용자가 선택한 목록 입니다.">
                          <caption>의약품 코드로 인한 복약 안내문 중 사용자가 선택한 목록</caption>
                          <colgroup>
                            <col width="100" />
                            <col width="*" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th scope="col">의약품명</th>
                              <th scope="col">안내문</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              drugGuideList.map((post, index) => (
                                <tr key={index}>
                                  <td>{post.drugName}</td>
                                  <td>
                                    {
                                      (post.typeBGuide !== null && post.typeBGuide.length > 0)
                                      &&
                                      (
                                        post.typeBGuide.split('\n').map((guide, idx) => (
                                          <div key={idx} className="pl6">
                                            <p><strong>({post.guideCategory} / {post.drugLevel})</strong></p>
                                            <span dangerouslySetInnerHTML={{ __html: guide.replaceAll('[', '<span class="highlighter">').replaceAll(']', '</span>') }}></span>
                                          </div>
                                        ))
                                      )
                                    }
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                  <div className="laybtn pt25">
                    <button onClick={closeLayer} className="btblack">닫기</button>
                    {/* <button type="button" className="btprimary">인쇄하기</button> */}
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedGuide;