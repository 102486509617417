import HeadTitle from '../common/headTitle';

function Balance() {
  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin" style={{'overflowX': 'scroll'}}>
            <HeadTitle project="charmacist" title="약국별 잔고현황" />
            <div className="subst" style={{'minWidth': '1400px'}}>
              <div className="d_table">
                <table className="dtable" summary="약국별 잔고현황 목록 입니다.">
                  <caption>약국별 잔고현황 목록</caption>
                  <thead>
                    <tr>
                      <th rowSpan="2">약국코드</th>
                      <th rowSpan="2">약국명</th>
                      <th rowSpan="2">현재잔고</th>
                      <th rowSpan="2">01/16 이후<br/>1.8%</th>
                      <th>12/16 ~ 01/15</th>
                      <th>11/16 ~ 12/15</th>
                      <th>10/16 ~ 11/15</th>
                      <th>10/15 이전</th>
                      <th rowSpan="2">01/16 이후<br/>주문</th>
                      <th>1/1 이후</th>
                      <th>12/16 ~ 01/15</th>
                      <th>12/1 ~ 12/31</th>
                      <th>11/16 ~ 12/15</th>
                      <th>11/1 ~ 11/30</th>
                      <th>10/16 ~ 11/15</th>
                    </tr>
                    <tr>
                      <th scope="col">1.8% 지급</th>
                      <th scope="col">1.2% 지급</th>
                      <th scope="col">0.6% 지급</th>
                      <th scope="col">프로모션 없음</th>
                      <th scope="col">당월결제</th>
                      <th scope="col">12/16~01/15<br/>주문</th>
                      <th scope="col">전월결제</th>
                      <th scope="col">11/16~12/15<br/>주문</th>
                      <th scope="col">11월결제</th>
                      <th scope="col">10/16~11/15<br/>주문</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">0</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">0</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">0</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                      <tr className="ov">
                        <td>5100000</td>
                        <td>참약사 약국</td>
                        <td className="red num">-100,204,534</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                        <td className="num">15,646,557</td>
                      </tr>
                    {
                      // postList.map(post => (
                      //   <tr key={post.odr_orderNo} className="ov" onClick={() => goDetail(post.odr_idx, post.odr_orderNo, post.odr_custCode)}>
                      //     <td>{post.odr_idx}</td>
                      //     <td>{post.odr_date}</td>
                      //     <td>{displayStatus(post.odr_status)}</td>
                      //     <td>{post.odr_title}</td>
                      //     <td>{post.odr_count}</td>
                      //     <td>{post.odr_qty}</td>
                      //     <td>{post.odr_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                      //     <td>{post.odr_modDate}</td>
                      //   </tr>
                      // ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Balance;