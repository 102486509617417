import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UrlContext from '../common/UrlContext';
import HeadTitle from '../common/headTitle';
import pagination from '../pagination/Pagination.module.css';

function Consult() {
  const navigate = useNavigate();

  const BASE_URL = useContext(UrlContext) + `consult`;
  const BLOCK_SIZE = 10;

  const [page, setPage] = useState({});
  const [no, setNo] = useState(1);
  const [cnt, setCnt] = useState([]);

  const [postList, setPostList] = useState([]);

  useEffect(() => {
    getPostList();
  }, [no]);

  const getPostList = () => {
    fetch(`${BASE_URL}/?page=${no}&size=${BLOCK_SIZE}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data !== undefined && data.code !== undefined && data.code === '200') {
          setPage(data.page);
          initPage(data.page);
          setPostList(data.data);
        }
      });
  };

  const initPage = (data) => {
    if (data !== undefined) {
      if (data.startPage === 1) {
        setCnt(Array.from({ length: data.endPage }, (_, i) => i + data.startPage));
      } else if (data.endPage === data.lastPage) {
        setCnt(Array.from({ length: data.lastPage - data.startPage + 1 }, (_, i) => i + data.startPage));
      } else {
        setCnt(Array.from({ length: BLOCK_SIZE }, (_, i) => i + data.startPage));
      }
    }
  }

 const firstPage = () => {
    goPage(1);
  }

  const lastPage = () => {
    goPage(page.lastPage);
  }

  const prevBlock = () => {
    if (no > 1) {
      goPage(no - BLOCK_SIZE);
    }
  }

  const nextBlock = () => {
    if (no < page.lastPage && no + BLOCK_SIZE < page.lastPage) {
      goPage(no + BLOCK_SIZE);
    } else {
      goPage(page.lastPage)
    }
  }

  const goPage = (pageNo) => {
    if (pageNo !== undefined && pageNo > 0) {
      setNo(pageNo);
    }
  }

  const goDetail = (idx) => {
    if (idx !== undefined) {
      navigate(`/charmacist/ConsultDetail/${idx}`);
    } else {
      navigate(`/charmacist/ConsultDetail`);
    }
  }

  return (
    <div id="container" className="container">
      <div id="contents" className="cont">
        <div className="section">
          <div className="sectionin">
            <HeadTitle project="charmacist" title="가입상담" />
            <div className="subst">
              <div className="d_table">
                <table className="dtable" summary="가입상담 목록 입니다.">
                  <caption>가입상담 목록</caption>
                  <colgroup>
                    <col width="80" />
                    <col width="140" />
                    <col width="80" />
                    <col width="180" />
                    <col width="*" />
                    <col width="*" />
                    <col width="100" />
                    <col width="100" />
                    <col width="180" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">구분</th>
                      <th scope="col">성명</th>
                      <th scope="col">이메일</th>
                      <th scope="col">전화번호</th>
                      <th scope="col">약국연락처</th>
                      <th scope="col">약국근무년수</th>
                      <th scope="col">대표약사여부</th>
                      <th scope="col">개국 관련 계획</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      postList.map(post => (
                        <tr key={post.idx} className="ov" onClick={() => goDetail(post.idx)}>
                          <td>{post.idx}</td>
                          <td>{post.category}</td>
                          <td>{post.userName}</td>
                          <td>{post.userEmail}</td>
                          <td>{post.phone}</td>
                          <td>{post.companyPhone}</td>
                          <td>{post.serviceYear}</td>
                          <td>{post.leader}</td>
                          <td>{post.plan}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="bbtn">
                <p className="bfr"><button onClick={() => goDetail()} className="btprimary">등록</button></p>
              </div>
              <div className={pagination.stpage + " mt20"}>
                <span><button onClick={firstPage} type="button" className={pagination.on}>&lt;&lt;</button></span>
                <span><button onClick={prevBlock} type="button" className={pagination.on}>&lt;</button></span>
                  {
                    cnt.map(cnt => (
                      <button key={cnt} onClick={() => { goPage(cnt) }} type="button" className={cnt === no ? pagination.on : ""}>{cnt}</button>
                    ))
                  }
                <span><button onClick={nextBlock} type="button" className={pagination.on}>&gt;</button></span>
                <span><button onClick={lastPage} type="button" className={pagination.on}>&gt;&gt;</button></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consult;